import { CurrencyCode } from "@outschool/gql-backend-generated";
import {
  shouldChargeInLocalCurrency,
  useCurrencyLocalization,
  useTranslation
} from "@outschool/localization";
import { addParamsToUrl } from "@outschool/routes";
import { usePaymentInfoEntered, useTrackEvent } from "@outschool/ui-analytics";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import * as Stripe from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import React from "react";

import * as Env from "../../../shared/Env";
import ActionType from "../../actions/ActionType";
import { useConvertToUserDefaultCurrency } from "../../hooks/Currency";
import {
  useDeletePaymentMethod,
  usePaymentMethods
} from "../../hooks/CurrentUser";
import { useAcceptPaymentWithStripe } from "../../queries/PaymentMutations";

export type BillingDetails = Stripe.PaymentMethodCreateParams.BillingDetails;

const STRIPE_PUBLISHABLE_KEY = Env.STRIPE_PUBLISHABLE_KEY;

export const useDefaultStripeErrorMessage = () => {
  const { t } = useTranslation("client\\components\\payments\\Stripe");
  return t(
    "Please try again in a few minutes. Our payment processor is temporarily unavailable."
  );
};

export const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, {
  apiVersion: Env.STRIPE_API_VERSION
});

export function StripeProvider({
  amountInCents,
  chargeInLocalCurrency,
  children
}: React.PropsWithChildren<{
  amountInCents: number;
  chargeInLocalCurrency?: boolean;
}>) {
  const { currencyCode } = useCurrencyLocalization();
  const isChargingInLocalCurrency =
    chargeInLocalCurrency && shouldChargeInLocalCurrency(currencyCode);

  const { convertCents } = useConvertToUserDefaultCurrency();

  const amountInCurrency = isChargingInLocalCurrency
    ? convertCents(amountInCents)
    : amountInCents;

  return (
    <div data-test-id="card-element">
      <Elements
        // We don't want to load Stripe.JS in the test environment as it is mocked and this creates log noise
        stripe={Env.isTest ? null : stripePromise}
        options={{
          mode: amountInCurrency === 0 ? "setup" : "payment",
          captureMethod: "manual",
          amount: amountInCurrency,
          currency: isChargingInLocalCurrency
            ? currencyCode.toLowerCase()
            : CurrencyCode.Usd.toLowerCase(),
          payment_method_types: ["card"]
        }}
      >
        {children}
      </Elements>
    </div>
  );
}

export function useGetPaymentIntent(): (
  clientSecret: any
) => Promise<Stripe.PaymentIntentResult> {
  const stripe = useStripe();

  // @ts-ignore TS(2322): Type '(clientSecret: string) => Promise<Stripe.Pay... Remove this comment to see the full error message
  return React.useCallback(
    (clientSecret: string) => {
      return stripe?.retrievePaymentIntent(clientSecret);
    },
    [stripe]
  );
}

export function useStripePayment({
  orderSlugId,
  amountCents,
  onStripePaymentAccepted,
  charterSchoolUid,
  purchaserEmail,
  exchangeRate,
  currencyCode
}: {
  orderSlugId: string;
  amountCents: number;
  onStripePaymentAccepted: (paymentIntentClientId: string) => Promise<void>;
  charterSchoolUid?: string;
  purchaserEmail?: string;
  exchangeRate?: number;
  currencyCode?: CurrencyCode;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [deletePaymentMethod] = useDeletePaymentMethod();
  const { paymentMethods } = usePaymentMethods();
  const createPaymentIntent = useAcceptPaymentWithStripe({
    orderSlugId,
    amountCents,
    charterSchoolUid,
    purchaserEmail,
    exchangeRate,
    currencyCode
  });
  const [defaultPaymentMethod] = paymentMethods;
  const trackEvent = useTrackEvent();
  const trackPaymentInfoEntered = usePaymentInfoEntered();

  const [billingDetails, setBillingDetails] = React.useState<BillingDetails>(
    {}
  );
  const [isCardComplete, setIsCardComplete] = React.useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = React.useState(false);
  const [isPaymentAccepted, setIsPaymentAccepted] = React.useState(false);
  const [orderPaymentUid, setOrderPaymentUid] = React.useState<string>();
  const [successfulPaymentIntentClientId, setSuccessfulPaymentIntentClientId] =
    React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null | undefined>(null);
  const errorMessage = useDefaultStripeErrorMessage();

  const mergeAndSetBillingDetails = React.useCallback(
    (billingDetailsToUpdate: BillingDetails) => {
      setBillingDetails(_.merge(billingDetails, billingDetailsToUpdate));
    },
    [billingDetails, setBillingDetails]
  );

  const { t } = useTranslation("client\\components\\payments\\Stripe");

  React.useEffect(() => {
    setError(null);
  }, [isCardComplete, billingDetails]);

  React.useEffect(() => {
    if (
      !isPaymentProcessing &&
      successfulPaymentIntentClientId &&
      !isPaymentAccepted
    ) {
      onStripePaymentAccepted(successfulPaymentIntentClientId);
      setIsPaymentAccepted(true);
    }
  }, [
    onStripePaymentAccepted,
    isPaymentAccepted,
    successfulPaymentIntentClientId,
    isPaymentProcessing
  ]);

  const payWithStripe = React.useCallback(
    async ({
      shouldSavePaymentMethod,
      shouldUseDefaultPaymentMethod,
      paymentRequestPaymentMethod
    }: {
      shouldSavePaymentMethod?: boolean;
      shouldUseDefaultPaymentMethod?: boolean;
      paymentRequestPaymentMethod?: Stripe.PaymentMethod;
    }): Promise<boolean> => {
      if (!stripe || !elements || isPaymentProcessing) {
        return false;
      }
      const useSavedCard =
        shouldUseDefaultPaymentMethod && defaultPaymentMethod;
      const useApplePay = !useSavedCard && paymentRequestPaymentMethod;

      setIsPaymentProcessing(true);
      try {
        const { paymentIntentClientId, orderPaymentUid: newOrderPaymentUid } =
          await createPaymentIntent({
            orderPaymentUid,
            purchaserName: billingDetails.name ?? undefined,
            savePaymentMethod: shouldSavePaymentMethod
          });
        const segmentTrackingProperties = {
          hasSavedCard: !!defaultPaymentMethod,
          usedSavedCard: useSavedCard,
          usedApplePay: useApplePay,
          savedCard: shouldSavePaymentMethod,
          orderPaymentUid: newOrderPaymentUid,
          deletedOldSavedCard: null,
          orderSlugId
        };
        trackEvent(
          ActionType.Payment.CARD_CONFIRMATION_ATTEMPT,
          segmentTrackingProperties
        );
        setOrderPaymentUid(newOrderPaymentUid);

        let result: Stripe.PaymentIntentResult;
        if (!useSavedCard) {
          elements.update({
            setup_future_usage: shouldSavePaymentMethod
              ? "off_session"
              : undefined
          });
          result = await stripe.confirmPayment({
            elements,
            clientSecret: paymentIntentClientId,
            confirmParams: {
              return_url: addParamsToUrl(
                window.location.href.replace(window.location.hash, ""),
                {
                  redirect: true,
                  order_slug_id: orderSlugId
                }
              )
            },
            redirect: "if_required"
          });
        } else {
          result = await stripe.confirmCardPayment(
            paymentIntentClientId,
            {
              payment_method: defaultPaymentMethod.uid,
              setup_future_usage: shouldSavePaymentMethod
                ? "off_session"
                : undefined
            },
            useApplePay ? { handleActions: false } : {}
          );
        }
        if (result.error) {
          if (
            currencyCode === CurrencyCode.Krw &&
            [
              "card_not_supported",
              "currency_not_supported",
              "transaction_not_allowed"
              // @ts-ignore TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            ].includes(result.error.decline_code)
          ) {
            setError(
              t(
                "Payment cannot be made with this card. If you are using 'DCC payment blocking service', please change the currency to USD in the country and currency settings at the bottom of the screen."
              )
            );
          } else if (
            result.error.type === "card_error" ||
            result.error.code === "payment_intent_authentication_failure"
          ) {
            // Show error to your customer (e.g., insufficient funds)
            setError(result.error.message);
          } else {
            throw result.error;
          }
        }
        // payment processed!
        else if (result.paymentIntent.status === "requires_capture") {
          setError(null);
          // Note: in case the card is declined,
          // we only remove old defaultPaymentMethod
          // after we confirm the new payment
          const deleteOldSavedCard =
            !shouldUseDefaultPaymentMethod &&
            defaultPaymentMethod &&
            shouldSavePaymentMethod;
          if (deleteOldSavedCard) {
            await deletePaymentMethod(defaultPaymentMethod.uid);
          }
          if (!useSavedCard || deleteOldSavedCard) {
            trackPaymentInfoEntered({
              orderSlugId,
              orderPaymentUid: newOrderPaymentUid,
              step: 2
            });
          }
          trackEvent(ActionType.Payment.CARD_CONFIRMED, {
            ...segmentTrackingProperties,
            deletedOldSavedCard: deleteOldSavedCard
          });
          setSuccessfulPaymentIntentClientId(paymentIntentClientId);
          return true;
        } else {
          // Something bad happened in payment intent creation
          throw new Error(
            `Invalid payment intent status, expected "requires_capture" but got "${result.paymentIntent.status}" instead.`
          );
        }
      } catch (err) {
        OsPlatform.captureError(err);
        if ((err?.message as string)?.includes("card_declined")) {
          setError("Your card has been declined.");
        } else {
          setError(errorMessage);
        }
        return false;
      } finally {
        // Always disable loading in case there's an error.
        // Moving it to the catch causes issues with infinite loading states.
        setIsPaymentProcessing(false);
      }
      return false;
    },
    [
      trackEvent,
      billingDetails,
      createPaymentIntent,
      defaultPaymentMethod,
      deletePaymentMethod,
      elements,
      isPaymentProcessing,
      stripe,
      orderPaymentUid,
      orderSlugId,
      currencyCode,
      t,
      errorMessage,
      trackPaymentInfoEntered
    ]
  );

  return {
    isPaymentProcessing,
    isPaymentSuccessful: Boolean(
      !isPaymentProcessing && successfulPaymentIntentClientId
    ),
    payWithStripe,
    error,
    isCardComplete,
    setIsCardComplete,
    setBillingDetails: mergeAndSetBillingDetails
  };
}
