import { Box, Button, Icon, Image, Typography } from "@outschool/backpack";
import { faArrowUp } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { useSession } from "@outschool/ui-auth";
import React, { useEffect, useState } from "react";

import outschoolGlyph from "../../../images/Outschool_Glyph.svg";
import PageModal from "../PageModal";
import { GetClassRecommendationsContent } from "../search/GetClassRecommendations";

const STATES = {
  initial: "INITIAL",
  findClass: "GET_CLASS_RECOMMENDATIONS",
  helpChat: "HELP_CHAT"
};

type AiPrincipalModalProps = {
  open: boolean;
  onClose: () => void;
};

export default function AiPrincipalModal({
  open,
  onClose
}: AiPrincipalModalProps) {
  const [aiPrincipalModalStage, setAiPrincipalModalStage] = useState(
    STATES.initial
  );

  // TODO: Call getClassInquiryByUser to get this number
  const numberOfDailyRequestsSubmitted = 0;

  const onModalClose = () => {
    onClose();
    setAiPrincipalModalStage(STATES.initial);
  };

  return (
    <>
      <PageModal
        open={open}
        onClose={onModalClose}
        sx={{ maxWidth: 500, maxHeight: "100vh" }}
      >
        {aiPrincipalModalStage === STATES.initial && (
          <InitialAiPrincipalModal setState={setAiPrincipalModalStage} />
        )}
        {aiPrincipalModalStage === STATES.findClass && (
          <GetClassRecommendationNavBarContent
            onClose={onModalClose}
            numberOfDailyRequestsSubmitted={numberOfDailyRequestsSubmitted}
          />
        )}
        {aiPrincipalModalStage === STATES.helpChat && (
          <AiPrincipalChatUI onClose={onModalClose} />
        )}
      </PageModal>
    </>
  );
}

type InitialAiPrincipalModalProps = {
  setState: (state: string) => void;
};

function InitialAiPrincipalModal({ setState }: InitialAiPrincipalModalProps) {
  const { t } = useTranslation(
    "client\\components\\localization\\AiPrincipalModal"
  );

  const { currentUser } = useSession();

  useEffect(() => {
    let script: HTMLScriptElement;
    if (!window.Intercom) {
      const appId = "d85jnqmd";
      script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${appId}`;
      document.body.appendChild(script);

      script.onload = () => {
        window.Intercom!("boot", {
          app_id: appId,
          user_hash: currentUser?.intercomUserHash
        });
        window.Intercom!("hide");
      };
    } else {
      window.Intercom("update");
    }

    return () => {
      if (window.Intercom && script) {
        document.body.removeChild(script);
      }
    };
  }, [currentUser]);
  return (
    <Box>
      <Typography variant="h3">{t`What would you like to do?`}</Typography>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px"
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Button
            sx={{ width: "100%" }}
            onClick={() => setState(STATES.findClass)}
          >
            {t`Find a new class`}
          </Button>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Button
            sx={{ width: "100%" }}
            onClick={() => setState(STATES.helpChat)}
          >
            {t`Help with current class`}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function GetClassRecommendationNavBarContent({
  onClose,
  numberOfDailyRequestsSubmitted
}: {
  onClose: () => void;
  numberOfDailyRequestsSubmitted: number;
}) {
  return (
    <GetClassRecommendationsContent
      onClose={onClose}
      numberOfDailyRequestsSubmitted={numberOfDailyRequestsSubmitted}
      isSearchContext={false}
    />
  );
}

function AiPrincipalChatUI({ onClose }: { onClose: () => void }) {
  const [text, setText] = useState("");

  const prefilledMessageIntercomWindow = (message: any) => {
    if (window.Intercom) {
      try {
        window.Intercom("showNewMessage", message || "Your Message");
        onClose(); // close the modal
      } catch (error) {
        console.error("Failed to show Intercom:", error);
      }
    } else {
      console.error("Intercom not initialized");
    }
  };
  return (
    <Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Image src={outschoolGlyph} sx={{ width: "50px", paddingX: "1rem" }} />
        <Box
          sx={{
            borderRadius: "10px",
            border: "1px solid rgba(75, 1, 212, 0.5)",
            paddingY: "0.5rem",
            paddingX: "1.5rem"
          }}
        >
          <Typography variant="body2">{`What can I do for you?`}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          marginY: "2rem",
          display: "flex",
          width: "100%",
          justifyContent: "flex-end"
        }}
      >
        <textarea
          style={{
            width: "100%",
            border: "1px solid rgba(75, 1, 212, 0.5)",
            borderRadius: "10px",
            padding: "10px",
            fontSize: ".85em",
            resize: "none"
          }}
          data-private
          placeholder={`type here`}
          value={text}
          onFocus={() => {}}
          onChange={e => {
            setText(e.currentTarget.value);
          }}
          maxLength={1000}
        />
        <Button
          sx={{ marginLeft: "10px", maxWidth: "100px" }}
          variant="contained"
          onClick={() => {
            prefilledMessageIntercomWindow(text);
          }}
        >
          <Icon icon={faArrowUp} sx={{ marginRight: "5px" }} />
          {`Send`}
        </Button>
      </Box>
    </Box>
  );
}
