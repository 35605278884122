import { Box, Typography } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { privacyPath, termsPath } from "@outschool/routes";
import { ExternalLink } from "@outschool/ui-components-shared";
import React from "react";

export default function NoCheckBoxDisclaimer() {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeAuthorization"
  );
  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        marginBottom: "1em",
        padding: 16,
        borderRadius: 8,
        width: "100%"
      }}
    >
      <Trans t={t}>
        <Typography variant={"body2"}>
          By placing this order, I agree to Outschool’s{" "}
          <ExternalLink url={termsPath()}>Terms of Services</ExternalLink> and{" "}
          <ExternalLink url={privacyPath()}>Privacy Policy</ExternalLink>.
        </Typography>
      </Trans>
    </Box>
  );
}
