import { Box, Button } from "@outschool/backpack";
import {
  TrackingEvent,
  getFundingProgramProperties,
  makeBannerTrackingName
} from "@outschool/funding-programs-shared";
import { Trans, useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { getEsaState } from "@outschool/ui-auth";
import { Banner } from "@outschool/ui-legacy-component-library";
import React from "react";

type Props = {
  onExitSession: () => void;
  esaState?: string;
};

export default function EsaBanner({ onExitSession }: Props) {
  const { t } = useTranslation("client\\components\\banners\\EsaBanner");
  const trackEvent = useTrackEvent();
  const trackViewedRef = React.useRef<boolean>(false);
  const esaState = getEsaState();
  const fundingProgramProperties = getFundingProgramProperties(esaState);
  const isClassWalletProgram = fundingProgramProperties?.isClassWallet;
  const fundingProgramName = fundingProgramProperties?.programName;

  const trackingProperties = React.useMemo(
    () => ({ fundingProgram: esaState, component: "EsaBanner" }),
    [esaState]
  );

  React.useEffect(() => {
    if (!trackViewedRef.current) {
      trackViewedRef.current = true;
      trackEvent(
        makeBannerTrackingName(TrackingEvent.BannerView),
        trackingProperties
      );
    }
  }, [trackEvent, trackingProperties]);

  if (!isClassWalletProgram || !fundingProgramName) {
    return null;
  }

  return (
    <Banner data-test-id="esa-banner" ref={trackViewedRef}>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Trans t={t}>
          <b>In a Classwallet Session:</b>&nbsp;Use your ClassWallet funds to
          enroll in {{ fundingProgramName }} eligible classes.{" "}
          <Button
            variant="link"
            sx={{
              fontSize: "1em",
              lineHeight: "1.75em",
              verticalAlign: "unset"
            }}
            onClick={() => {
              trackEvent(
                makeBannerTrackingName(TrackingEvent.OptOutTouch),
                trackingProperties
              );
              onExitSession();
            }}
          >
            {t("Exit ClassWallet Session")}
          </Button>{" "}
          {t("to pay by other methods.")}
        </Trans>
      </Box>
    </Banner>
  );
}
