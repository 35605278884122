// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box } from "@outschool/backpack";
import { ErrorBoundary } from "@outschool/ui-legacy-component-library";
import { isChunkLoadError } from "@outschool/ui-utils";
import React from "react";

import { duplicateError } from "../lib/duplicateError";
import { PrerenderErrorMetaTag } from "./PrerenderHeaderTags";

export default function SimpleErrorBoundary({ children }: $TSFixMe) {
  return (
    <ErrorBoundary
      fallback={error => {
        error =
          error ||
          new Error("fallback called but no error message was passed?");
        /*
         * Errors are deduplicated by Sentry, which will clobber any tags we
         * apply. To make sure that the tags are included, we'll wrap the
         * original error.
         */
        const wrappedError = duplicateError(
          error,
          "Oops page displayed for error: "
        );
        OsPlatform.captureError(wrappedError, {
          tags: {
            oops_page_displayed: true
          }
        });
        return <DefaultErrorBoundaryMessage error={error} />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export function DefaultErrorBoundaryMessage({ error }: { error?: any }) {
  return (
    <>
      <PrerenderErrorMetaTag />
      <Box
        sx={{
          textAlign: "center",
          paddingTop: "2em"
        }}
      >
        <Box
          sx={{
            maxWidth: "360px",
            py: "0.75em",
            px: "1em",
            textAlign: "center",
            margin: "auto"
          }}
        >
          Oops! Something went wrong with the Outschool website. Our engineers
          have been notified.{" "}
          {isChunkLoadError(error) ? (
            <>
              Please check that your browser is up to date and that your
              internet connection is stable, then reload the page. If the
              problem continues after trying these steps, please contact us at{" "}
              <a href="mailto:support@outschool.com">support@outschool.com</a>
              {". "}
            </>
          ) : (
            <>
              Please reload the page and try again, or contact us at{" "}
              <a href="mailto:support@outschool.com">support@outschool.com</a>{" "}
              if the error persists.{" "}
            </>
          )}
          You can also check{" "}
          <a href="https://status.outschool.com/">status.outschool.com</a> to
          see if there is a broader outage.
          <br />
          <br />
          If you have a class, you should still be able to join the class
          through a banner at the top of{" "}
          <a href="https://outschool.com/">outschool.com</a>.
          <br />
          <br />
          Thanks for bearing with us!
        </Box>
      </Box>
    </>
  );
}
