import { Box, Typography } from "@outschool/backpack";
import {
  ActivityEnrollQueryQuery,
  EnrollmentMeetingPurchaseQueryQuery
} from "@outschool/gql-frontend-generated";
import * as Localization from "@outschool/localization";
import { Trans, useTranslation } from "@outschool/localization";
import { privacyPath, termsPath } from "@outschool/routes";
import { ExternalLink } from "@outschool/ui-components-shared";
import { Checkbox } from "@outschool/ui-legacy-component-library";
import React from "react";

import * as Routes from "../../../shared/Routes";
import NoCheckBoxDisclaimer from "./NoCheckBoxDisclaimer";

export enum BulkPurchaseRecurringPaymentTypes {
  Fixed = "fixed",
  Ongoing = "ongoing",
  Mixed = "mixed"
}

export const STRIPE_TOS_URL = "https://stripe.com/legal/consumer";
export const STRIPE_PRIVACY_POLICY_URL = "https://stripe.com/privacy";

type StripeAuthorizationProps = {
  setIsSaveForLater: (isSaved: boolean) => void;
  isSaveForLater: boolean;
  isPaymentProcessing: boolean;
  isPaymentSuccessful: boolean;
  activity?:
    | NonNullable<ActivityEnrollQueryQuery["enrollmentForm"]>["activity"]
    | NonNullable<
        EnrollmentMeetingPurchaseQueryQuery["enrollmentMeetingPurchaseForm"]
      >["activity"];
  isWeeklyPayment?: boolean;
  bulkPurchaseRecurringPaymentMethod?: BulkPurchaseRecurringPaymentTypes;
  isThreeStepBooking?: boolean;
  durationWeeks?: number | null;
};

export const bulkStopRecurringMethodText = (
  t: Localization.TFunction,
  bulkPurchasePaymentMethod: BulkPurchaseRecurringPaymentTypes
) => {
  switch (bulkPurchasePaymentMethod) {
    case BulkPurchaseRecurringPaymentTypes.Fixed:
      return t("“Withdraw” link");
    case BulkPurchaseRecurringPaymentTypes.Ongoing:
      return t("“Stop Subscription” link");
    case BulkPurchaseRecurringPaymentTypes.Mixed:
      return t("“Withdraw” or “Stop Subscription” link");
  }
};

export default function StripeAuthorization({
  setIsSaveForLater,
  isSaveForLater,
  isPaymentProcessing,
  isPaymentSuccessful,
  activity,
  isWeeklyPayment,
  bulkPurchaseRecurringPaymentMethod,
  isThreeStepBooking,
  durationWeeks
}: StripeAuthorizationProps) {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeAuthorization"
  );
  const locale = Localization.useLocale();

  let disclaimerText;

  // TODO: Is this used anywhere? Can we remove it?
  if (!!bulkPurchaseRecurringPaymentMethod) {
    const stopPaymentMethod = bulkStopRecurringMethodText(
      t,
      bulkPurchaseRecurringPaymentMethod
    );
    // For bulk checkout, don't include the payment amount or charge time info
    disclaimerText = (
      <Trans t={t}>
        <p>
          I acknowledge the charges will automatically be charged weekly on
          Sunday. To remove your learner from a class and stop recurring
          charges, please visit the classroom page for the class and click on
          the {{ stopPaymentMethod }}.{" "}
          <ExternalLink
            url={Routes.understandingOngoingClassesSupportUrl(locale)}
            trackingName="checkout_ongoing_class_support_link"
          >
            Learn more
          </ExternalLink>
        </p>
        <p>
          I authorize Outschool, Inc. to charge my credit card as payment for
          the classes on a recurring basis through its third-party vendor, Link
          by Stripe, Inc., processing such personal information in accordance
          with their{" "}
          <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
          and{" "}
          <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
            Privacy Policy
          </ExternalLink>
          . Outschool does not store or process this information.
        </p>
      </Trans>
    );
  } else if (activity && !!activity.is_ongoing_weekly) {
    disclaimerText = (
      <Trans t={t}>
        <p>
          By placing this order, I agree to Outschool’s{" "}
          <ExternalLink url={termsPath()}>Terms of Services</ExternalLink> and{" "}
          <ExternalLink url={privacyPath()}>Privacy Policy</ExternalLink>.
          Additionally, I authorized Outschool, through Stripe, Inc., to
          automatically charge my payment on a weekly basis for this class as
          described.
        </p>
      </Trans>
    );
  } else if (activity && activity.hasTeacherSchedule) {
    if (isThreeStepBooking) {
      // If durationWeeks is 1, we render the disclaimer for a one-time purchase.
      if (durationWeeks === 1) {
        return <NoCheckBoxDisclaimer />;
      }

      disclaimerText = (
        <Trans t={t}>
          <p>
            By placing this order, I agree to Outschool’s{" "}
            <ExternalLink url={termsPath()}>Terms of Services</ExternalLink> and{" "}
            <ExternalLink url={privacyPath()}>Privacy Policy</ExternalLink>.
            Additionally, I authorized Outschool, through Stripe, Inc., to
            automatically charge my payment on a weekly basis for this class as
            described.
          </p>
        </Trans>
      );
    } else {
      disclaimerText = (
        <Trans t={t}>
          <p>
            By placing this order, I agree to Outschool’s{" "}
            <ExternalLink url={termsPath()}>Terms of Services</ExternalLink> and{" "}
            <ExternalLink url={privacyPath()}>Privacy Policy</ExternalLink>.
            Additionally, I authorized Outschool, through Stripe, Inc., to
            automatically charge my payment on a weekly basis for this class as
            described.
          </p>
        </Trans>
      );
    }
  } else if (activity && activity.isClub) {
    disclaimerText = (
      <Trans t={t}>
        <p>
          By placing this order, I agree to Outschool’s{" "}
          <ExternalLink url={termsPath()}>Terms of Services</ExternalLink> and{" "}
          <ExternalLink url={privacyPath()}>Privacy Policy</ExternalLink>.
          Additionally, I authorized Outschool, through Stripe, Inc., to
          automatically charge my payment on a monthly basis for this class as
          described.
        </p>
      </Trans>
    );
  } else if (isWeeklyPayment) {
    disclaimerText = (
      <Trans t={t}>
        <p>
          By placing this order, I agree to Outschool’s{" "}
          <ExternalLink url={termsPath()}>Terms of Services</ExternalLink> and{" "}
          <ExternalLink url={privacyPath()}>Privacy Policy</ExternalLink>.
          Additionally, I authorized Outschool, through Stripe, Inc., to
          automatically charge my payment on a weekly basis for this class as
          described.
        </p>
      </Trans>
    );
  } else {
    return <NoCheckBoxDisclaimer />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        marginBottom: "1em",
        padding: 16,
        borderRadius: 8,
        width: "100%"
      }}
    >
      <Box
        flex
        sx={{
          fontSize: "0.875em"
        }}
      >
        <Checkbox
          id="disclaimer-checkbox"
          checked={isSaveForLater}
          disabled={isPaymentProcessing || isPaymentSuccessful}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setIsSaveForLater(e.target.checked)
          }
        />

        <label htmlFor="disclaimer-checkbox" style={{ marginBottom: 0 }}>
          <Typography
            variant="body2"
            component="div"
            sx={{
              marginLeft: "0.5em",
              "p:not(:first-of-type)": {
                paddingTop: 8
              }
            }}
          >
            {disclaimerText}
          </Typography>
        </label>
      </Box>
    </Box>
  );
}
