import { Box } from "@outschool/backpack";
import {
  CurrentUserQueryQuery,
  CurrentUserQueryQueryVariables
} from "@outschool/gql-frontend-generated";
import { BASE_LOCALE, createUseTranslationHook } from "@outschool/localization";
import { useIdentify, useTrackEvent } from "@outschool/ui-analytics";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import {
  SessionProvider,
  currentUserQuery,
  useTokenContext
} from "@outschool/ui-auth";
import { useTopNoticeContext } from "@outschool/ui-components-website";
import { lazyWithRetry } from "@outschool/ui-utils";
import React, { ErrorInfo, PropsWithChildren, ReactNode } from "react";
import { useLocation } from "react-router";

import shareImage from "../../images/2023_Evergreen_FacebookPost.jpg";
import logo300Image from "../../images/Outschool_SocialIcon.png";
import {
  useDefaultPageDescription,
  useDefaultPageTitle
} from "../../shared/PageMetadata";
import EsaRedirect from "../components/EsaRedirect";
import HeaderTags from "../components/HeaderTags";
import SimpleLayout from "../components/layouts/SimpleLayout";
import { DefaultErrorBoundaryMessage } from "../components/SimpleErrorBoundary";
import WelcomeModal from "../components/WelcomeModal";
import useExperimentOverridesEnabled from "../hooks/useExperimentOverridesEnabled";
import { useIsTranslating } from "../hooks/useIsTranslating";
import { duplicateError } from "../lib/duplicateError";
import { useAppState } from "../stores/AppStateProvider";

const useForcedTranslation = createUseTranslationHook(() => true);

const ExperimentOverrideContainer = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ExperimentOverrideContainer" */ "../components/ExperimentOverride"
    )
);

export default (props: $TSFixMe) => {
  const location = useLocation();

  // Let props overwrite url for tests with custom AppContainers
  return <AppContainer url={location.pathname + location.search} {...props} />;
};

interface AppContainerProps {
  url: string;
}

interface AppContainerState {
  errorBoundaryError?: Error;
}

class AppContainer extends React.Component<
  PropsWithChildren<AppContainerProps>,
  AppContainerState
> {
  state = { errorBoundaryError: undefined };

  componentDidUpdate(
    prevProps: AppContainerProps,
    _prevState: AppContainerState,
    _snapshot: $TSFixMe
  ) {
    if (
      this.props.url !== prevProps.url &&
      Boolean(this.state.errorBoundaryError)
    ) {
      this.setState({ errorBoundaryError: undefined });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /*
     * Errors are deduplicated by Sentry, which will clobber any tags we
     * apply. To make sure that the tags are included, we'll wrap the
     * original error.
     */
    const wrappedError = duplicateError(
      error,
      "Oops page displayed for error: "
    );
    OsPlatform.captureError(wrappedError, {
      extra: { ...errorInfo },
      tags: {
        oops_page_displayed: true
      }
    });
    this.setState({ errorBoundaryError: error });
    this.forceUpdate(); // If the error happens while navigating setState was not enough for some reason.
  }

  render() {
    const { children } = this.props;
    const { errorBoundaryError } = this.state;

    return (
      // @ts-ignore TS(2322): Type 'undefined' is not assignable to type 'Error'... Remove this comment to see the full error message
      <ContainerWithCurrentUser errorBoundaryError={errorBoundaryError}>
        {children}
        <WelcomeModal />
        <EsaRedirect />
      </ContainerWithCurrentUser>
    );
  }
}

const useTranslationNotice = () => {
  const { t, i18n } = useForcedTranslation("client");
  const { setTopNotice } = useTopNoticeContext();
  const isTranslating = useIsTranslating();

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (i18n.language !== BASE_LOCALE && !isTranslating) {
        setTopNotice(
          t(
            "We're in the process of translating our site to English, but some pages aren't quite done yet. In the meantime, try updating your browser's language settings."
          )
        );
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [isTranslating, i18n.language, setTopNotice, t]);
};

function ContainerWithCurrentUser({
  errorBoundaryError,
  children
}: {
  errorBoundaryError: Error;
  children: ReactNode;
}) {
  useTranslationNotice();
  const [identified, setIdentified] = React.useState(false);
  const identify = useIdentify();
  const trackEvent = useTrackEvent();

  const { data, refetch: currentUserRefetch } = useQueryWithPreviousData<
    CurrentUserQueryQuery,
    CurrentUserQueryQueryVariables
  >(currentUserQuery, {
    fetchPolicy: "cache-only"
  });
  const currentUser = data?.currentUser || undefined;
  const areOverridesEnabled = useExperimentOverridesEnabled();
  const {
    getTokens,
    authData: {
      isAdmin,
      isLoggedIn,
      isLeader,
      hasApple,
      hasFacebook,
      hasGoogle,
      hasLine,
      hasKakao,
      hasPassword,
      hasAccount,
      roles
    },
    isEsaSession,
    esaSessionData,
    esaSessionType,
    destroyEsaSession
  } = useTokenContext();

  const { currentUserIsLoading, currentUserHasLoaded } = useAppState();

  React.useEffect(() => {
    if (!!errorBoundaryError) {
      trackEvent("oops_error_page", {
        errorName: errorBoundaryError.name,
        errorMessage: errorBoundaryError.message
      });
    }
  }, [errorBoundaryError, trackEvent]);

  React.useEffect(() => {
    if (currentUserHasLoaded) {
      /*
       * Only logged-out users are identified here. All logged-in users are
       * identified in stores/AppState.ts.
       */
      if (!isLoggedIn && !identified) {
        identify();

        /*
         * For logged out users, identify does not call Intercom's boot method,
         * so we manually call it here instead. This is required to display any
         * Intercom widget to a logged out user.
         */
        if (!!window.Intercom) {
          window.Intercom("boot", {
            app_id: "d85jnqmd",
            hide_default_launcher: true
          });
        }
      } else if (isLoggedIn) {
        setIdentified(true);
      }
    }
  }, [currentUserHasLoaded, isLoggedIn, identified, identify]);

  const defaultDescription = useDefaultPageDescription();
  const defaultTitle = useDefaultPageTitle();

  return (
    <SessionProvider
      {...{
        currentUserIsLoading,
        currentUserHasLoaded,
        currentUser,
        currentUserRefetch,
        isAdmin,
        isLoggedIn,
        isLeader,
        hasApple,
        hasFacebook,
        hasGoogle,
        hasLine,
        hasKakao,
        hasPassword,
        hasAccount,
        roles,
        isEsaSession,
        esaSessionData,
        esaSessionType,
        destroyEsaSession,
        sessionToken: getTokens().sessionToken
      }}
    >
      {errorBoundaryError ? (
        <SimpleLayout>
          <DefaultErrorBoundaryMessage error={errorBoundaryError} />
        </SimpleLayout>
      ) : (
        <Box
          id="app-container"
          sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {/* eslint-disable i18next/no-literal-string */}
          <HeaderTags
            meta={[
              {
                name: "description",
                content: defaultDescription
              },
              {
                property: "og:title",
                content: defaultTitle
              },
              {
                property: "og:description",
                content: defaultDescription
              },
              {
                property: "og:image",
                content: shareImage
              },
              { property: "og:image:width", content: "1200" },
              { property: "og:image:height", content: "630" }
            ]}
            script={[
              {
                type: "application/ld+json",
                innerHTML: `{
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Outschool",
                "legalName": "Outschool, Inc.",
                "url": "https://outschool.com/",
                "logo": "${logo300Image}",
                "email": "support@outschool.com",
                "contactPoint": [{
                  "@type": "ContactPoint",
                  "email": "support@outschool.com",
                  "contactType": "customer service"
                }],
                "sameAs": [
                  "https://www.facebook.com/outschool/",
                  "https://twitter.com/outschooler",
                  "https://www.pinterest.com/outschool",
                  "https://www.linkedin.com/company/outschool"
                ]
              }`
              }
            ]}
          />
          {/* eslint-enable i18next/no-literal-string */}
          {children}
          <div id="IntercomDefaultWidget" />
          {areOverridesEnabled && <ExperimentOverrideContainer />}
        </Box>
      )}
    </SessionProvider>
  );
}
