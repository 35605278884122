import { Box, Button } from "@outschool/backpack";
import {
  FundingProgramAbbreviation,
  TrackingEvent,
  getFundingProgramProperties,
  makeBannerTrackingName
} from "@outschool/funding-programs-shared";
import { useTranslation } from "@outschool/localization";
import { searchPath } from "@outschool/routes";
import { useTrackEvent } from "@outschool/ui-analytics";
import { ExternalLink } from "@outschool/ui-components-shared";
import { Banner } from "@outschool/ui-legacy-component-library";
import { useNavigation } from "@outschool/ui-utils";
import Cookies from "js-cookie";
import React, { useRef, useState } from "react";

export default function FloridaEMABanner() {
  const { t } = useTranslation("client\\components\\banners\\FloridaEMABanner");
  const navigate = useNavigation();

  const floridaEMASession = Cookies.get("floridaEMASession");
  if (floridaEMASession === undefined) {
    Cookies.set("floridaEMASession", "true"); // opted in by default
  }
  const [isOptedIn, setIsOptedIn] = useState(floridaEMASession ?? "true");

  const trackViewedRef = useRef<boolean>(false);

  const trackingProperties = React.useMemo(
    () => ({
      fundingProgram: FundingProgramAbbreviation.EMA,
      component: "FloridaEMABanner",
      isOptedIn
    }),
    [isOptedIn]
  );

  const trackEvent = useTrackEvent();

  React.useEffect(() => {
    if (!trackViewedRef.current) {
      trackViewedRef.current = true;
      trackEvent(
        makeBannerTrackingName(TrackingEvent.BannerView),
        trackingProperties
      );
    }
  }, [trackEvent, trackingProperties]);

  const fundingProgramProperties = getFundingProgramProperties(
    FundingProgramAbbreviation.EMA
  );
  const programNameLong = fundingProgramProperties?.programNameLong;
  const programUrl = fundingProgramProperties?.programUrl;

  const handleOptIn = () => {
    Cookies.set("floridaEMASession", "true");
    setIsOptedIn("true");
    trackEvent(
      makeBannerTrackingName(TrackingEvent.OptInTouch),
      trackingProperties
    );
    navigate(searchPath(), { hardNav: true });
  };

  const handleOptOut = () => {
    Cookies.set("floridaEMASession", "false");
    setIsOptedIn("false");
    trackEvent(
      makeBannerTrackingName(TrackingEvent.OptOutTouch),
      trackingProperties
    );
    navigate(searchPath(), { hardNav: true });
  };

  const handleClose = () => {
    trackEvent(
      makeBannerTrackingName(TrackingEvent.DismissTouch),
      trackingProperties
    );
  };

  return (
    <Banner
      data-test-id="ema-funding-banner"
      closable={true}
      onClose={handleClose}
      ref={trackViewedRef}
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "baseline"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isOptedIn === "true" ? (
            <Box>
              {t("Viewing Florida-approved classes. ")}
              <Button
                variant="link"
                sx={{
                  fontSize: "1em",
                  lineHeight: "1.75em",
                  verticalAlign: "unset"
                }}
                onClick={handleOptOut}
              >
                {t("Turn off Florida EMA filter", {
                  programNameLong
                })}
              </Button>
              {t(" to see all classes.")}
            </Box>
          ) : (
            <Box>
              {t("Looking to use your Florida EMA funds? ")}
              <Button
                variant="link"
                sx={{
                  fontSize: "1em",
                  lineHeight: "1.75em",
                  verticalAlign: "unset"
                }}
                onClick={handleOptIn}
              >
                {t("Click here to show all Florida-approved educators.")}
              </Button>
              {t(
                " Learn more about using your Florida scholarship for live classes "
              )}
              <ExternalLink
                trackingName={makeBannerTrackingName(TrackingEvent.ProgramUrl)}
                trackingSharedProperties={trackingProperties}
                url={programUrl ?? ""}
              >
                {t("here")}
              </ExternalLink>
            </Box>
          )}
        </Box>
      </Box>
    </Banner>
  );
}
