import {
  Alert,
  BasicModal,
  Box,
  Button,
  Icon,
  Menu,
  MenuItem,
  TextField,
  Theme,
  Typography
} from "@outschool/backpack";
import { Search } from "@outschool/business-rules";
import {
  ClassInquiriesByUserQuery,
  CurrentUserQueryQuery,
  RequestClassInquiryMutation
} from "@outschool/gql-frontend-generated";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faExclamationTriangle,
  faWandMagicSparkles
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useMutation, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import { omit } from "lodash";
import React, { useCallback, useContext, useState } from "react";

import loadingGif from "../../../images/loading.gif";
import { isDevelopment } from "../../../shared/Env";
import {
  SearchFiltersContext,
  useSearchFilters
} from "../../lib/useSearchFilters";
import {
  classInquiriesByUserQuery,
  requestClassInquiryMutation
} from "../../queries/ClassInquiryQueries";

type CurrentUser = NonNullable<CurrentUserQueryQuery["currentUser"]>;
type Learner = NonNullable<CurrentUser["children"][number]>;

const MAX_NUMBER_OF_CHARACTERS_IN_TEXT_FIELD = 1200;
const MAX_NUMBER_OF_CLASS_INQUIRIES_PER_USER_PER_DAY = isDevelopment ? 100 : 3;

export function GetClassRecommendationsButton() {
  const { t } = useTranslation(
    "client\\components\\search\\GetClassRecommendations"
  );
  const { currentUser } = useSession();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const trackEvent = useTrackEvent();
  const { searchUid } = useSearchFilters();

  const {
    loading: isLoadingClassInquiriesByUser,
    data: { getClassInquiriesByUser } = { getClassInquiriesByUser: [] }
  } = useQueryWithPreviousData<ClassInquiriesByUserQuery>(
    classInquiriesByUserQuery,
    {
      variables: {
        userUid: currentUser?.uid
      },
      fetchPolicy: "network-only"
    }
  );

  const onOpen = useCallback(() => {
    setIsModalOpen(true);
    trackEvent("class_recommendations_modal_opened", { searchUid });
  }, [trackEvent, searchUid]);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
    trackEvent("class_recommendations_modal_closed", { searchUid });
  }, [trackEvent, searchUid]);

  const numberOfDailyRequestsSubmitted = getClassInquiriesByUser.length;

  return isLoadingClassInquiriesByUser ||
    numberOfDailyRequestsSubmitted >=
      MAX_NUMBER_OF_CLASS_INQUIRIES_PER_USER_PER_DAY ? null : (
    <Box
      sx={(theme: Theme) => ({
        position: "sticky",
        // place above logged-in footer on mobile
        bottom: "80px",
        marginTop: "1em",
        marginX: "auto",
        width: "95vw",
        [theme.breakpoints.up("sm")]: {
          bottom: "40px",
          width: "400px",
          marginTop: 0
        }
      })}
    >
      <Button
        onClick={onOpen}
        variant="contained"
        size="large"
        endIcon={<Icon icon={faWandMagicSparkles} />}
        sx={{
          width: "100%"
        }}
      >
        {t("Get Class Recommendations")}
      </Button>
      {isModalOpen && (
        <GetClassRecommendationsModal
          onClose={onClose}
          numberOfDailyRequestsSubmitted={numberOfDailyRequestsSubmitted}
        />
      )}
    </Box>
  );
}

function GetClassRecommendationsModal({
  onClose,
  numberOfDailyRequestsSubmitted
}: {
  onClose: () => void;
  numberOfDailyRequestsSubmitted: number;
}) {
  const { t } = useTranslation(
    "client\\components\\search\\GetClassRecommendations"
  );

  return (
    <BasicModal
      hasCloseButton
      closeButtonArialLabel={t("Close")}
      open={true}
      onClose={onClose}
      modalContentProps={{ sx: { padding: "2em" } }}
    >
      <GetClassRecommendationsContent
        onClose={onClose}
        numberOfDailyRequestsSubmitted={numberOfDailyRequestsSubmitted}
      />
    </BasicModal>
  );
}

export function GetClassRecommendationsContent({
  onClose,
  numberOfDailyRequestsSubmitted,
  isSearchContext = true
}: {
  onClose: () => void;
  numberOfDailyRequestsSubmitted: number;
  isSearchContext?: boolean;
}) {
  const trackEvent = useTrackEvent();
  const { currentUser } = useSession();
  const searchContext = useContext(SearchFiltersContext);
  const { searchUid } = isSearchContext ? searchContext : { searchUid: null };
  const { filters } = useSearchFilters();
  const navigate = useNavigation();

  const [learnerUid, setLearnerUid] = React.useState<string>("");
  const [inputValue, setInputValue] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const [
    requestClassInquiry,
    { data: classInquiryData, loading: isLoadingRequestClassInquiry }
  ] = useMutation<RequestClassInquiryMutation>(requestClassInquiryMutation);

  const numberOfCharactersRemaining =
    MAX_NUMBER_OF_CHARACTERS_IN_TEXT_FIELD - (inputValue?.length || 0);

  function onChangeText(value: string) {
    setInputValue(value);
  }

  const onSelectLearner = useCallback(
    (uid: string) => {
      setLearnerUid(uid);
    },
    [setLearnerUid]
  );

  const onSubmit = useCallback(async () => {
    // We have to omit the SCHEDULE_PLANNER_TRACKER because it isn't a filter
    // and if we submit it to GQL endpoints it'll throw errors
    const cleanedFilters = {
      ...omit(filters, [Search.SCHEDULE_PLANNER_TRACKER])
    };

    try {
      // TODO: Show topRecommendedActivity in frontend after request is submitted
      await requestClassInquiry({
        variables: {
          userUid: currentUser?.uid,
          inquiryText: inputValue,
          learnerUid: learnerUid,
          searchFilters: cleanedFilters
        }
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
    isSearchContext &&
      trackEvent("class_recommendations_modal_submitted", { searchUid });
  }, [
    requestClassInquiry,
    currentUser?.uid,
    inputValue,
    learnerUid,
    filters,
    setErrorMessage,
    trackEvent,
    searchUid,
    isSearchContext
  ]);

  const onClickKeepSearching = useCallback(() => {
    onClose();
    if (
      !isLoadingRequestClassInquiry &&
      classInquiryData?.requestClassInquiry?.searchPath
    ) {
      navigate(classInquiryData.requestClassInquiry.searchPath);
    }
  }, [onClose, isLoadingRequestClassInquiry, classInquiryData, navigate]);

  return (
    <>
      {!isLoadingRequestClassInquiry && classInquiryData ? (
        <ConfirmationScreen
          onClickKeepSearching={onClickKeepSearching}
          isSearchContext={isSearchContext}
        />
      ) : (
        <InputScreen
          isLoadingRequestClassInquiry={isLoadingRequestClassInquiry}
          onSelectLearner={onSelectLearner}
          errorMessage={errorMessage}
          inputValue={inputValue}
          onChangeText={onChangeText}
          numberOfCharactersRemaining={numberOfCharactersRemaining}
          onSubmit={onSubmit}
          numberOfDailyRequestsSubmitted={numberOfDailyRequestsSubmitted}
        />
      )}
    </>
  );
}

function InputScreen({
  isLoadingRequestClassInquiry,
  errorMessage,
  onSelectLearner,
  inputValue,
  onChangeText,
  numberOfCharactersRemaining,
  onSubmit,
  numberOfDailyRequestsSubmitted
}: {
  isLoadingRequestClassInquiry: boolean;
  errorMessage: string;
  onSelectLearner: (uid: string) => void;
  inputValue: string;
  onChangeText: (value: string) => void;
  numberOfCharactersRemaining: number;
  onSubmit: () => void;
  numberOfDailyRequestsSubmitted: number;
}) {
  const { t } = useTranslation(
    "client\\components\\search\\GetClassRecommendations"
  );
  return (
    <Box>
      <Typography sx={{ fontWeight: 700, marginBottom: "0.5em" }} variant="h4">
        {t("Get class recommendations")}
      </Typography>
      <Typography
        sx={{ fontWeight: 400, marginBottom: "1em" }}
        variant="subtitle1"
      >
        {t(
          "What class are you looking for? We'll share your request with top teachers who will message you back. Add as much detail as possible."
        )}
      </Typography>

      {isLoadingRequestClassInquiry ? (
        <LoadingSpinner />
      ) : (
        <InputArea
          errorMessage={errorMessage}
          onSelectLearner={onSelectLearner}
          inputValue={inputValue}
          onChangeText={onChangeText}
          numberOfCharactersRemaining={numberOfCharactersRemaining}
          onSubmit={onSubmit}
          numberOfDailyRequestsSubmitted={numberOfDailyRequestsSubmitted}
        />
      )}
    </Box>
  );
}

function LoadingSpinner() {
  const { t } = useTranslation(
    "client\\components\\search\\GetClassRecommendations"
  );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <img src={loadingGif} width={100} alt={t("Loading")} />
    </Box>
  );
}

function InputArea({
  errorMessage,
  onSelectLearner,
  inputValue,
  onChangeText,
  numberOfCharactersRemaining,
  onSubmit,
  numberOfDailyRequestsSubmitted
}: {
  errorMessage: string;
  onSelectLearner: (uid: string) => void;
  inputValue: string;
  onChangeText: (value: string) => void;
  numberOfCharactersRemaining: number;
  onSubmit: () => void;
  numberOfDailyRequestsSubmitted: number;
}) {
  const { t } = useTranslation(
    "client\\components\\search\\GetClassRecommendations"
  );

  const onSelect = useCallback(
    (uid: string) => {
      onSelectLearner(uid);
    },
    [onSelectLearner]
  );

  return (
    <Box flex sx={{ flexDirection: "column", gap: "1em" }}>
      {errorMessage && (
        <Alert severity="error" icon={<Icon icon={faExclamationTriangle} />}>
          {errorMessage}
        </Alert>
      )}
      <LearnerSelection label={t("Select Learner")} onSelect={onSelect} />
      <TextField
        multiline
        fullWidth
        rows={6}
        value={inputValue}
        onChange={e => onChangeText(e.target.value)}
        inputProps={{
          maxLength: MAX_NUMBER_OF_CHARACTERS_IN_TEXT_FIELD
        }}
        helperText={t("{{numberOfCharactersRemaining}} characters remaining", {
          numberOfCharactersRemaining
        })}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={!inputValue}
          endIcon={<Icon icon={faChevronRight} />}
          sx={(theme: Theme) => ({
            [theme.breakpoints.down("sm")]: {
              width: "70%"
            }
          })}
        >
          {t("Share with teachers")}
        </Button>
        <Typography
          sx={{
            fontWeight: 400,
            textAlign: "right"
          }}
          variant="subtitle1"
        >
          {t(
            "{{numberOfDailyRequestsSubmitted}}/{{maxNumberOfDailyRequests}} daily requests submitted",
            {
              numberOfDailyRequestsSubmitted,
              maxNumberOfDailyRequests:
                MAX_NUMBER_OF_CLASS_INQUIRIES_PER_USER_PER_DAY
            }
          )}
        </Typography>
      </Box>
    </Box>
  );
}

function LearnerSelection({
  label,
  onSelect
}: {
  label: string;
  onSelect: (uid: string) => void;
}) {
  const { currentUser, currentUserHasLoaded } = useSession();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLearnerName, setSelectedLearnerName] = useState("");
  const [selectedLearnerAge, setSelectedLearnerAge] = useState(0);
  const { t } = useTranslation(
    "client\\components\\search\\GetClassRecommendations"
  );

  const open = Boolean(anchorEl);

  const learners: Learner[] = React.useMemo(() => {
    return currentUserHasLoaded && currentUser?.children
      ? [...currentUser.children].sort((a, b) => {
          const ageA = a.age || 0;
          const ageB = b.age || 0;
          return ageA - ageB;
        }) || []
      : [];
  }, [currentUser?.children, currentUserHasLoaded]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (
    learnerUid: string,
    learnerName: string,
    learnerAge: number
  ) => {
    onSelect(learnerUid);
    setSelectedLearnerName(learnerName);
    setSelectedLearnerAge(learnerAge);
    handleClose();
  };

  return currentUserHasLoaded && learners && learners.length ? (
    <Box flex sx={{ alignItems: "center", flexDirection: "row", gap: "0.5em" }}>
      <Button
        size="small"
        onClick={handleClick}
        sx={{
          width: "18rem",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {selectedLearnerName
            ? t("{{learnerName}} (age {{learnerAge}})", {
                learnerName: selectedLearnerName,
                learnerAge: selectedLearnerAge
              })
            : label}
        </Typography>
        <Icon icon={open ? faChevronUp : faChevronDown} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          maxHeight: "30rem",
          borderRadius: "8px",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            boxShadow:
              "0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 16px 32px 0px rgba(0, 0, 0, 0.10)",
            "& ul": {
              padding: 0,
              "& li:hover": { borderRadius: "0 !important" }
            }
          }
        }}
      >
        {(learners || []).map((learner: Learner) => (
          <MenuItem
            key={learner.uid}
            onClick={() =>
              handleSelect(learner.uid, learner.name ?? "", learner.age ?? 0)
            }
          >
            {t("{{learnerName}} (age {{learnerAge}})", {
              learnerName: learner.name,
              learnerAge: learner.age
            })}
          </MenuItem>
        ))}
      </Menu>
      <Typography variant="subtitle2" sx={{ color: "grey.500" }}>
        {t("Optional")}
      </Typography>
    </Box>
  ) : null;
}

function ConfirmationScreen({
  onClickKeepSearching,
  isSearchContext = false
}: {
  onClickKeepSearching: () => void;
  isSearchContext: boolean;
}) {
  const { t } = useTranslation(
    "client\\components\\search\\GetClassRecommendations"
  );
  return (
    <Box>
      <Typography sx={{ fontWeight: 700, marginBottom: "0.75em" }} variant="h4">
        {t("Your request is submitted and being reviewed!")}
      </Typography>
      {isSearchContext && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "auto"
          }}
        >
          <Button variant="contained" onClick={onClickKeepSearching}>
            {t("Keep searching")}
          </Button>
        </Box>
      )}
    </Box>
  );
}
