import { gql } from "@outschool/ui-apollo";

export default gql`
  fragment EnrollmentPaymentFragment on EnrollmentPayment {
    uid
    amount_cents
    order_slug_id
    stripe_charge_id
    purchase_order_id
    esa_order_id
    intercom_url
    last4
    created_at
    paid_at
    disbursed_at
    payout_uid
    start_time
    end_time
    unrefundedCents
    isOngoingRefundable
    voucher_name
    pricing_offer_uid
    credit_transaction_uid
    coupon_code_uid
    currency_code
    exchange_rate
    invoice_uid
    funding_program_invoice_uid
    service_fee_percentage
    couponCode {
      code
      coupon {
        couponType
      }
    }
    pricingOffer {
      name
      buyerOrgName
      isFinancialAid
    }
  }
`;

export const EnrollmentPaymentReceiptFragment = gql`
  fragment EnrollmentPaymentReceiptFragment on EnrollmentPayment {
    uid
    amount_cents
    amount_credits
    order_slug_id
    stripe_charge_id
    purchase_order_id
    pricing_offer_uid
    esa_order_id
    invoice_uid
    funding_program_invoice_uid
    intercom_url
    last4
    created_at
    paid_at
    disbursed_at
    payout_uid
    start_time
    end_time
    unrefundedCents
    isOngoingRefundable
    voucher_name
    credit_transaction_uid
    credit_account_type
    service_fee_percentage
    coupon_code_uid
    currency_code
    exchange_rate
    couponCode {
      code
      coupon {
        couponType
      }
    }
    pricingOffer {
      name
      buyerOrgName
      isFinancialAid
    }
    stripeReceiptUrl
    stripePaymentMethod
    service_fee_percentage
  }
`;
