import {
  Divider,
  DividerProps,
  Drawer,
  DrawerProps,
  Icon,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps as MenuItemPropsBase,
  MenuProps,
  menuItemClasses,
  useUniqueId
} from "@outschool/backpack";
import {
  GetLearnerModeTransferTokenMutation,
  GetLearnerModeTransferTokenMutationVariables
} from "@outschool/gql-frontend-generated";
import {
  IconDefinition,
  faArrowLeft,
  faBullhorn,
  faCalendarAlt,
  faChalkboardTeacher,
  faChartLine,
  faCog,
  faComment,
  faFileAlt,
  faGift,
  faGlobeRegular,
  faHeart,
  faHome,
  faMoneyBillAlt,
  faQuestionCircle,
  faSearch,
  faSignIn,
  faSignOut,
  faUserPlus,
  faUsersCog,
  fasGift
} from "@outschool/icons";
import {
  TFunction,
  useCurrencyLocalization,
  useTranslation
} from "@outschool/localization";
import {
  browseRootPath,
  giftCardLandingPath,
  leadActivitiesPath,
  savedClassesPath,
  searchPath,
  sellerOrgPath,
  teachPath,
  teachTipsUrl,
  teacherSchedulePath
} from "@outschool/routes";
import { useMutation } from "@outschool/ui-apollo";
import {
  AuthTrigger,
  useLoginFlowContext,
  useSession
} from "@outschool/ui-auth";
import {
  AvatarImage,
  DEFAULT_AVATAR,
  ExternalLink,
  TrackedButton,
  TrackedButtonProps
} from "@outschool/ui-components-shared";
import {
  NavHelpLink,
  useHideLearningPlannerExperiment,
  useUserWalletSummary
} from "@outschool/ui-components-website";
import { Screen, useIsTablet } from "@outschool/ui-utils";
import { sortBy } from "lodash";
import React from "react";

import * as Routes from "../../../shared/Routes";
import * as User from "../../../shared/User";
import ActionType from "../../actions/ActionType";
import { usePricingOfferAdmin } from "../../hooks/CurrentUser";
import { useCurrentUserLearners } from "../../hooks/Learners";
import {
  getOrgAdminRoutesFromUser,
  isBuyerOrgAdmin
} from "../../lib/BuyerOrgAdmins";
import * as UserMode from "../../lib/UserMode";
import { isOrganizationAdminWhoCanEnroll } from "../../lib/Users";
import { getLearnerModeTransferToken } from "../../queries/AuthenticationQueries";
import { useAppState } from "../../stores/AppStateProvider";
import IconBadgeWithNumber from "../IconBadgeWithNumber";
import Link from "../Link";
import LocalizationPickerMobile from "../localization/LocalizationPickerMobile";
import LocalizationPickerModal from "../localization/LocalizationPickerModal";
import { EducatorTab, useEducatorPinnedTabs } from "./EducatorModeToolbar";
import { WalletNavItem } from "./WalletNavItem";

export type HeaderMenuButtonProps = TrackedButtonProps & {
  isOpen: boolean;
  buttonRef: React.Ref<any>;
};

type HeaderMenuProps = {
  onClose?: MenuProps["onClose"];
  opensDrawer?: boolean;
  /**
   * Children is the button / component that will open the menu
   * @param props HeaderMenuButtonProps
   * @returns
   */
  children: (props: HeaderMenuButtonProps) => React.ReactNode;
};

export const HeaderMenu = React.memo(
  ({ onClose, opensDrawer = false, children }: HeaderMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const buttonId = useUniqueId("HeaderMenu-button");
    const menuId = useUniqueId("HeaderMenu-menu");

    const { t } = useTranslation("client\\components\\nav\\HeaderMenu");
    const menuItems = useMenuItems();

    const handleButtonClick = () => {
      setIsOpen(true);
    };

    const handleMenuClose = (
      e: Parameters<NonNullable<MenuItemProps["onClick"]>>[0],
      reason?: any
    ) => {
      setIsOpen(false);
      onClose?.(e, reason);
    };

    const additionalProps = opensDrawer
      ? {}
      : {
          anchorEl,
          MenuListProps: {
            "aria-labelledby": buttonId
          }
        };

    return (
      <>
        {children({
          id: buttonId,
          onClick: handleButtonClick,
          isOpen,
          buttonRef: setAnchorEl
        })}
        <MenuOrDrawer
          id={menuId}
          aria-labelledby={buttonId}
          open={isOpen}
          onClose={handleMenuClose}
          isDrawer={opensDrawer}
          {...additionalProps}
        >
          {menuItems.map((MenuItem, index) => (
            <MenuItem
              key={`menu-item-${index}`}
              onClick={handleMenuClose}
              t={t}
            />
          ))}
        </MenuOrDrawer>
      </>
    );
  }
);

type MenuOrDrawerProps<D extends boolean> = React.PropsWithChildren<
  {
    isDrawer: D;
  } & (D extends true ? DrawerProps : MenuProps)
>;

const MenuOrDrawer = <D extends boolean>({
  isDrawer,
  children,
  ...props
}: MenuOrDrawerProps<D>) => {
  if (isDrawer) {
    return (
      <Drawer
        /* eslint-disable i18next/no-literal-string */
        anchor="right"
        /* eslint-enable i18next/no-literal-string */
        {...(props as DrawerProps)}
      >
        <List>{children}</List>
      </Drawer>
    );
  }

  return (
    <Menu
      /* eslint-disable i18next/no-literal-string */
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      /* eslint-enable i18next/no-literal-string */
      {...(props as MenuProps)}
    >
      {children}
    </Menu>
  );
};

const useMenuItems = (): React.FC<MenuItemProps | DividerProps>[] => {
  const { isLoggedIn, currentUser, isLeader, currentUserHasLoaded } =
    useSession();
  const learners = useCurrentUserLearners();
  const isMobile = Screen.useIsMobile();
  const isDesktop = Screen.useIsLarge();
  const pricingOfferAdminOrgUid = usePricingOfferAdmin();
  const userMode = UserMode.useUserMode();
  const { hideLearningPlanner } = useHideLearningPlannerExperiment();
  const isEducator =
    !!currentUser?.teacher_approved_at && !currentUser?.suspended_at;
  const hasActiveSubscription = !!currentUser?.hasSubscription;

  const {
    totalBalanceCents: walletBalance,
    loading: walletBalanceLoading,
    pricingOffers
  } = useUserWalletSummary(isDesktop);
  const showWalletFunds =
    (walletBalance > 0 || pricingOffers.length > 0) &&
    !walletBalanceLoading &&
    !isDesktop &&
    !hasActiveSubscription;

  if (!currentUserHasLoaded) {
    // Don't show items while we are determining the user's logged in status
    // currentUserHasLoaded will also be true when a user is not logged in
    return [];
  }

  const isEducatorMode = userMode === UserMode.UserMode.Educator;
  const hasLearner = learners.length > 0;
  const isBuyerOrgAdminMode = isBuyerOrgAdmin(currentUser);
  const isEnrollingOrgAdmin = isOrganizationAdminWhoCanEnroll(currentUser);
  const isSellerOrgAdmin = currentUser?.sellerOrg?.currentUserIsOwner ?? false;
  const isSellerOrgApplicant = currentUser?.latestSellerOrgApplication ?? false;

  const hasTeacherApplication = User.hasTeacherApplication(currentUser);

  if (!isLoggedIn) {
    return [
      ...(isMobile ? [LoggedOutHome, FindClasses] : []),
      GiftCard,
      Help,
      HeaderMenuDivider,
      Localization,
      Join,
      Login
    ];
  }

  const loggedInMenuItems = [
    HeaderMenuDivider,
    Localization,
    Settings,
    Help,
    Logout
  ];

  if (isEducatorMode) {
    // Educator mode menu
    return [
      ...(isMobile ? [SwitchToParent, HeaderMenuDivider] : []),
      ...(hasLearner ? [LearnerLinks, HeaderMenuDivider] : []),
      ...(isMobile
        ? currentUser?.sellerOrg?.currentUserIsTeacher
          ? [
              TeacherHome,
              Conversations,
              TeacherSchedule,
              TeacherSections,
              EducatorLibrary,
              HeaderMenuDivider
            ]
          : [
              TeacherHome,
              ...(hasTeacherApplication ? [TeacherApply] : []),
              EducatorProfileTabs,
              EducatorLibrary,
              HeaderMenuDivider
            ]
        : []),
      ...(currentUser?.latestSellerOrgApplication ? [SellerOrgManage] : []),
      ...(pricingOfferAdminOrgUid ? [Analytics, PricingOfferDashboard] : []),
      InviteTeacher,
      InviteParent,
      ...loggedInMenuItems
    ];
  }

  if (isBuyerOrgAdminMode) {
    // Buyer org admin mode menu
    return [
      ...(isMobile ? [Home] : []),
      ...(isMobile && isEnrollingOrgAdmin ? [FindClasses] : []),
      ...(isMobile && pricingOfferAdminOrgUid ? [Analytics] : []),
      ...(isMobile ? [HeaderMenuDivider] : []),
      Conversations,
      Favorites,
      ...(isEnrollingOrgAdmin ? [Transactions] : []),
      ...loggedInMenuItems
    ];
  }

  // Parent mode menu
  return [
    ...(isMobile && isLeader && !isSellerOrgAdmin
      ? [SwitchToEducatorBold, HeaderMenuDivider]
      : []),
    ...(showWalletFunds ? [UserWallet, HeaderMenuDivider] : []),
    ...(hasLearner ? [LearnerLinks, HeaderMenuDivider] : []),
    isEducator ? OUTreach : Give20Get20,
    LearnerSchedule,
    ...(hideLearningPlanner ? [Favorites] : []),
    Transactions,
    Transcripts,
    ...(isSellerOrgApplicant || (isMobile && pricingOfferAdminOrgUid)
      ? [HeaderMenuDivider]
      : []),
    ...(isSellerOrgApplicant ? [SellerOrgManage] : []),
    ...(isMobile && pricingOfferAdminOrgUid ? [PricingOfferDashboard] : []),
    HeaderMenuDivider,
    ...(isLeader ? [TeachOnOutSchool] : []),
    InviteTeacher,
    GiftCard,
    ...loggedInMenuItems
  ];
};

type HeaderMenuItemPropsBase<B extends React.ElementType> =
  MenuItemPropsBase<B> & {
    label: React.ReactNode | string;
    icon?: IconDefinition;
    textProps?: React.ComponentPropsWithoutRef<typeof ListItemText>;
    iconProps?: React.ComponentPropsWithoutRef<typeof ListItemIcon>;
    // Something is wrong with MenuItemPropsBase's component prop, and it's not coming through
    component?: B;
  };

const HeaderMenuItem = React.forwardRef(
  <B extends React.ElementType>(
    { label, icon, textProps, iconProps, ...props }: HeaderMenuItemPropsBase<B>,
    ref: React.Ref<any>
  ) => (
    <MenuItem {...props} ref={ref}>
      {icon && (
        <ListItemIcon
          {...iconProps}
          sx={[
            { color: "primary.500" },
            ...(iconProps?.sx && Array.isArray(iconProps.sx)
              ? iconProps.sx
              : [iconProps?.sx])
          ]}
        >
          <Icon icon={icon} color="inherit" />
        </ListItemIcon>
      )}
      <ListItemText {...textProps}>{label}</ListItemText>
    </MenuItem>
  )
);

type HeaderMenuItemLink = Omit<
  HeaderMenuItemPropsBase<typeof Link>,
  "component"
>;

const HeaderMenuItemLink = React.forwardRef(
  (props: HeaderMenuItemLink, ref: React.Ref<any>) => (
    <HeaderMenuItem component={Link} {...props} ref={ref} />
  )
);

const HeaderMenuDivider = () => (
  <Divider
    sx={{
      marginY: "0.8rem !important"
    }}
  />
);

type MenuItemProps = MenuItemPropsBase & {
  t: TFunction;
};

const Home = ({
  t,
  useHardNav,
  ...props
}: MenuItemProps & { useHardNav?: boolean }) => {
  const { currentUser } = useSession();
  const { homeRoute } = getOrgAdminRoutesFromUser(currentUser);
  return (
    <HeaderMenuItemLink
      label={t("Home")}
      icon={faHome}
      to={homeRoute ?? browseRootPath()}
      useHardNav={useHardNav}
      {...props}
    />
  );
};

// we want to be sent to SSR home if we are not logged in, so this uses hard nav
const LoggedOutHome = (props: MenuItemProps) => <Home {...props} useHardNav />;

const SwitchToParent = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Switch to Parent")}
    icon={faHome}
    to={Routes.parentRootPath()}
    {...props}
  />
);

const SwitchToEducatorBold = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Switch to Educator")}
    icon={faHome}
    to={teachPath()}
    // eslint-disable-next-line i18next/no-literal-string
    textProps={{ sx: { "> span": { fontWeight: "fontWeightBold" } } }}
    {...props}
  />
);

const FindClasses = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Search")}
    icon={faSearch}
    to={searchPath()}
    trackingName="nav_find_classes"
    {...props}
  />
);

const SellerOrgManage = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Manage")}
    icon={faUsersCog}
    to={sellerOrgPath()}
    trackingName="nav_manage_link"
    {...props}
  />
);

const TeachOnOutSchool = ({ t, ...props }: MenuItemProps) => {
  const { isLeader } = useSession();

  return (
    <HeaderMenuItemLink
      label={t("Teach on Outschool")}
      icon={faChalkboardTeacher}
      to={isLeader ? teachPath() : leadActivitiesPath()}
      trackingName="nav_teach_link"
      {...props}
    />
  );
};

const TeacherHome = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Home")}
    icon={faArrowLeft}
    iconProps={{ sx: { color: "primary.200" } }}
    to={Routes.teachBasePath()}
    {...props}
  />
);

const Help = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItem
    label={t("Help")}
    icon={faQuestionCircle}
    component={NavHelpLink}
    sx={{ flex: 1 }}
    // Add a variant that isn't "link" because that removes the background by force
    // which ultimately makes the button not have a hover state.
    variant="contained"
    {...props}
  />
);

const Conversations = ({ t, ...props }: MenuItemProps) => {
  const { currentUser, isLeader } = useSession();

  return (
    <HeaderMenuItemLink
      label={
        <>
          {t("Conversations")}
          <IconBadgeWithNumber
            color="red"
            count={User.unreadConversationsCount(currentUser)}
            dataTestId={"unread-message-count"}
          />
        </>
      }
      icon={faComment}
      to={Routes.conversationsPath(
        isLeader ? UserMode.UserMode.Educator : UserMode.UserMode.Parent
      )}
      {...props}
    />
  );
};

const Favorites = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Favorites")}
    icon={faHeart}
    to={savedClassesPath()}
    trackingName="nav_account_favorites"
    {...props}
  />
);

const Transactions = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Transactions")}
    icon={faMoneyBillAlt}
    to={Routes.transactionsPath()}
    trackingName="nav_account_payments"
    {...props}
  />
);

const GiftCard = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Give a Gift Card")}
    icon={faGift}
    to={giftCardLandingPath()}
    {...props}
  />
);

const Join = ({ t, onClick, ...props }: MenuItemProps) => {
  const { enterLoginFlow } = useLoginFlowContext();
  return (
    <HeaderMenuItem
      label={t("Join")}
      icon={faUserPlus}
      onClick={(e: any) => {
        enterLoginFlow({
          authTrigger: AuthTrigger.JOIN_OUTSCHOOL
        });
        onClick?.(e);
      }}
      {...props}
    />
  );
};

const Login = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Log In")}
    icon={faSignIn}
    to={Routes.loginPath()}
    {...props}
  />
);

const Logout = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Log Out")}
    icon={faSignOut}
    to={Routes.logoutPath()}
    {...props}
  />
);

const Settings = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Settings")}
    icon={faCog}
    to={Routes.parentSettingsPath()}
    {...props}
  />
);

const Analytics = ({ t, ...props }: MenuItemProps) => {
  const { currentUser } = useSession();

  const { analyticsRoute } = getOrgAdminRoutesFromUser(currentUser);
  if (!analyticsRoute) {
    return null;
  }

  return (
    <HeaderMenuItemLink
      label={t("Analytics")}
      icon={faChartLine}
      to={analyticsRoute}
      {...props}
    />
  );
};

const OUTreach = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Give $20, Get $50")}
    icon={fasGift}
    to={Routes.invitePath()}
    trackingName="nav_outreach_link_dropdown"
    // eslint-disable-next-line i18next/no-literal-string
    textProps={{ sx: { "> span": { fontWeight: "fontWeightBold" } } }}
    iconProps={{ sx: { color: "primary.main" } }}
    {...props}
  />
);

const Give20Get20 = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Give $20, Get $20")}
    icon={fasGift}
    to={Routes.invitePath()}
    trackingName="nav_g20g20_link_dropdown"
    // eslint-disable-next-line i18next/no-literal-string
    textProps={{ sx: { "> span": { fontWeight: "fontWeightBold" } } }}
    iconProps={{ sx: { color: "primary.main" } }}
    {...props}
  />
);

const TeacherSchedule = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Schedule")}
    // icon={faCalendarAlt}
    to={teacherSchedulePath()}
    {...props}
  />
);

const EducatorLibrary = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItem
    component={ExternalLink}
    href={teachTipsUrl()}
    label={t("Educator Library")}
    icon={faArrowLeft}
    iconProps={{ sx: { color: "primary.200" } }}
    {...props}
  />
);

const TeacherSections = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Sections")}
    to={Routes.teacherSectionsPath()}
    {...props}
  />
);

const InviteTeacher = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Invite a Teacher")}
    icon={faBullhorn}
    to={Routes.referTeachersPath()}
    {...props}
  />
);

const InviteParent = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Invite a Parent")}
    icon={faBullhorn}
    to={Routes.invitePath()}
    {...props}
  />
);

const TeacherApply = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Apply")}
    icon={faBullhorn}
    to={Routes.teacherApplyPath()}
    {...props}
  />
);

const PricingOfferDashboard = ({ t, ...props }: MenuItemProps) => {
  const pricingOfferAdminOrgUid = usePricingOfferAdmin();

  if (!pricingOfferAdminOrgUid) {
    return null;
  }

  return (
    <HeaderMenuItemLink
      label={t("Pricing Offer Dashboard")}
      icon={faChartLine}
      to={Routes.pricingOfferAdminDashboardForOrganizationPath(
        pricingOfferAdminOrgUid
      )}
      trackingName="nav_pricing_offer_dashbaord"
      {...props}
    />
  );
};

const Transcripts = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Transcripts")}
    icon={faFileAlt}
    to={Routes.transcriptsPath()}
    trackingName="nav_account_transcripts"
    {...props}
  />
);

const LearnerSchedule = ({ t, ...props }: MenuItemProps) => (
  <HeaderMenuItemLink
    label={t("Enrolled")}
    icon={faCalendarAlt}
    to={Routes.learnSchedulePath()}
    trackingName="nav_account_schedule"
    {...props}
  />
);

const EducatorProfileTabs = ({ t: _t, ...props }: MenuItemProps) => {
  const { orderedTabs } = useEducatorPinnedTabs();

  return (
    <>
      {orderedTabs.map((tab: EducatorTab) => {
        const { value, label, to, Badge } = tab;
        return (
          <HeaderMenuItemLink
            key={`tab-item-${value}`}
            label={
              <>
                {label}
                {Badge ? <Badge /> : null}
              </>
            }
            to={to}
            icon={faArrowLeft}
            iconProps={{ sx: { color: "primary.200" } }}
            {...props}
          />
        );
      })}
    </>
  );
};

const Localization = ({ t, onClick: _onClick, ...props }: MenuItemProps) => {
  const isTablet = useIsTablet();

  const [localizationMenuOpen, setLocalizationMenuOpen] =
    React.useState<boolean>(false);
  const openLocalizationMenu = () => setLocalizationMenuOpen(true);
  const closeLocalizationMenu = () => {
    setLocalizationMenuOpen(false);
  };

  const trackingName = "localization_picker";

  const handleClick = () => {
    openLocalizationMenu();
  };

  const isDesktop = Screen.useIsLarge();
  const { isLoggedIn } = useSession();
  const { hasLoaded: localizationHasLoaded, currencyCode } =
    useCurrencyLocalization();
  const displayLocalizationPicker =
    localizationHasLoaded && (isLoggedIn || !isDesktop) && currencyCode;

  if (!displayLocalizationPicker) {
    return null;
  }

  return (
    <>
      <HeaderMenuItem
        component={TrackedButton}
        variant="link"
        trackingName={trackingName}
        trackingUniqueId={trackingName}
        trackingSharedProperties={{
          // eslint-disable-next-line i18next/no-literal-string
          platformView: "mobile"
        }}
        icon={faGlobeRegular}
        label={t("Language & Region")}
        onClick={handleClick}
        data-test-id="header-localization-picker"
        {...props}
      />
      {isTablet ? (
        <LocalizationPickerMobile
          open={localizationMenuOpen}
          onClose={closeLocalizationMenu}
          onBack={closeLocalizationMenu}
        />
      ) : (
        <LocalizationPickerModal
          open={localizationMenuOpen}
          onClose={closeLocalizationMenu}
        />
      )}
    </>
  );
};

function LearnerLinks(props: MenuItemProps) {
  const { t } = useTranslation("client\\components\\nav\\HeaderMenu");
  const { currentUser } = useSession();
  const appState = useAppState();
  const learners = useCurrentUserLearners();

  const [getTransferToken] = useMutation<
    GetLearnerModeTransferTokenMutation,
    GetLearnerModeTransferTokenMutationVariables
  >(getLearnerModeTransferToken);

  if (!learners || learners.length === 0) {
    return null;
  }

  const handleLearnerClick = async (
    e: Parameters<NonNullable<MenuItemProps["onClick"]>>[0],
    learnerUid: string
  ) => {
    props.onClick?.(e);
    const { data } = await getTransferToken({ variables: { learnerUid } });
    appState.appDispatcher.dispatch(ActionType.User.REDIRECT_TO_LEARNER_APP, {
      transferToken: data?.learnerModeTransferToken?.transferToken,
      authRequirement: currentUser?.learnerModeSwitchAuthRequirement
    });
  };

  const sortedLearners = sortBy(learners, l => l.name);

  return (
    <>
      <MenuItem
        sx={{
          [`&.${menuItemClasses.disabled}`]: {
            opacity: 1,
            fontWeight: 500,
            color: "grey.700"
          }
        }}
        /* When listSubheader is the first menu item keyboard nav stops working, so use a jank styled MenuItem instead */
        /* `disabled` and `autoFocus` is a kinda hack that allows arrow down after opening the menu */
        disabled
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      >
        {t`Switch to:`}
      </MenuItem>
      {sortedLearners.map(learner => (
        <MenuItem
          key={learner.uid}
          onClick={(e: Parameters<NonNullable<MenuItemProps["onClick"]>>[0]) =>
            handleLearnerClick(e, learner.uid)
          }
        >
          <ListItemIcon
            sx={{
              height: "24px"
            }}
          >
            <AvatarImage
              avatar={learner.avatar || DEFAULT_AVATAR}
              width={24}
              height={24}
              name={learner.name}
            />
          </ListItemIcon>
          <ListItemText className="amp-block">{learner.name}</ListItemText>
        </MenuItem>
      ))}
    </>
  );
}

const UserWallet = ({ onClick }: MenuItemProps) => (
  <WalletNavItem onClick={onClick as any} />
);
