import {
  Box,
  Divider,
  Image,
  ModalCloseButton,
  Typography,
  styled
} from "@outschool/backpack";
import { imageUrl } from "@outschool/filestack-urls";
import { ReviewReminderTopNoticeBarQuery } from "@outschool/gql-frontend-generated";
import { useLocalStorageState } from "@outschool/local-storage";
import { useTranslation } from "@outschool/localization";
import { addParamsToUrl, pathFromLocation } from "@outschool/routes";
import { gql, useQuery } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { StarInput } from "@outschool/ui-components-classroom";
import { NudgeModal, TrackedButton } from "@outschool/ui-components-shared";
import {
  useDevicePixelRatio,
  useDeviceTypeContext,
  useNavigation
} from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router-dom";

import * as Routes from "../../shared/Routes";

const CLASS_IMAGE_SIZE = 72;
const TEACHER_IMAGE_SIZE = 32;

const SX_TRUNCATE_TWO_LINES = {
  // truncate text with ellipsis after 2 lines
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical"
};

export const reviewReminderQuery = gql`
  query ReviewReminderTopNoticeBar {
    currentUser {
      uid
      enrolledSectionThatNeedsReview {
        uid
        leader {
          uid
          photo
          name
        }
        activity {
          uid
          slug_id
          titleTranslated
          details {
            photo
          }
        }
      }
    }
  }
`;

type ReviewNudgeProps = {
  section: NonNullable<
    NonNullable<
      ReviewReminderTopNoticeBarQuery["currentUser"]
    >["enrolledSectionThatNeedsReview"]
  >;
  path: string;
  incrementSkips: () => void;
};

function getSkipDays(skips: number): number {
  if (skips <= 2) {
    return 1;
  } else if (skips === 3) {
    return 3;
  } else {
    return 7;
  }
}

function hasRecentEmptyQuery(lastEmptyQuery?: string): boolean {
  if (lastEmptyQuery) {
    const lastEmptyQueryDate = new Date(lastEmptyQuery);
    // 4 hours
    const skipMs = 4 * 60 * 60 * 1000;
    if (new Date().getTime() - lastEmptyQueryDate.getTime() < skipMs) {
      return true;
    }
  }
  return false;
}

// Return true if the user has skipped the nudge recently
// and is not eligible to see it again
export function hasRecentlySkipped(skips: number, lastSeen?: string): boolean {
  if (skips === 0) {
    return false;
  }
  if (lastSeen) {
    const lastSeenDate = new Date(lastSeen);
    const skipMs = getSkipDays(skips) * 24 * 60 * 60 * 1000;
    if (new Date().getTime() - lastSeenDate.getTime() < skipMs) {
      return true;
    }
  }
  return false;
}

const StyledCloseButton = styled(ModalCloseButton)({
  position: "absolute",
  top: 8,
  right: 8,
  fontSize: "2.4rem"
});

function ReviewNudge({ section, path, incrementSkips }: ReviewNudgeProps) {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigation();
  const { t } = useTranslation(
    "client\\components\\ReviewReminderTopNoticeBar"
  );
  const { deviceType } = useDeviceTypeContext();
  const pixelRatio = useDevicePixelRatio(deviceType);
  const { activity } = section;
  const classPhotoUrl = imageUrl(activity.details.photo, {
    height: CLASS_IMAGE_SIZE * pixelRatio,
    width: CLASS_IMAGE_SIZE * pixelRatio,
    fit: "crop"
  });
  const teacherPhotoUrl = imageUrl(section.leader.photo, {
    height: TEACHER_IMAGE_SIZE * pixelRatio,
    width: TEACHER_IMAGE_SIZE * pixelRatio,
    fit: "crop"
  });
  const onClose = () => {
    incrementSkips();
    setOpen(false);
  };

  const onStarClick = (rating: number) => {
    const ratingPath = addParamsToUrl(path, { rating });
    incrementSkips();
    setOpen(false);
    navigate(ratingPath);
  };

  const onWriteReviewClick = () => {
    incrementSkips();
    navigate(path);
  };

  const onSkipClick = () => {
    onClose();
  };

  return (
    <NudgeModal open={open} onClose={onClose}>
      <StyledCloseButton onClick={onClose} aria-label={t("close")} />
      <Typography variant="h5" emphasized>
        {t("How was your class?")}
      </Typography>
      <Divider sx={{ my: 12 }} />
      <Box flex sx={{ gap: 16 }}>
        <Image
          sx={{
            width: CLASS_IMAGE_SIZE,
            height: CLASS_IMAGE_SIZE,
            borderRadius: 8,
            objectFit: "cover"
          }}
          src={classPhotoUrl}
          height={CLASS_IMAGE_SIZE}
          width={CLASS_IMAGE_SIZE}
          alt={t("Class listing photo")}
        />
        <Box>
          <Typography
            component="div"
            sx={{
              fontWeight: "500",
              mb: "0.5rem",
              ...SX_TRUNCATE_TWO_LINES
            }}
          >
            {activity.titleTranslated}
          </Typography>
          <Box flex sx={{ alignItems: "center", gap: 12 }}>
            <Image
              sx={{
                width: TEACHER_IMAGE_SIZE,
                height: TEACHER_IMAGE_SIZE,
                borderRadius: 99, // round
                objectFit: "cover"
              }}
              src={teacherPhotoUrl}
              height={TEACHER_IMAGE_SIZE}
              width={TEACHER_IMAGE_SIZE}
              alt={t("Teacher photo")}
            />
            <Typography
              sx={{
                fontWeight: "500",
                color: "grey.600",
                lineHeight: "1.2",
                ...SX_TRUNCATE_TWO_LINES
              }}
            >
              {section.leader.name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 12 }} />
      <Box flex sx={{ justifyContent: "center", mb: 8 }}>
        <StarInput
          value={0}
          onChange={onStarClick}
          id="reviewReminder"
        ></StarInput>
      </Box>
      <Box flex sx={{ justifyContent: "center", mb: 16 }}>
        <TrackedButton
          variant="contained"
          onClick={onWriteReviewClick}
          sx={{ px: "6rem" }}
          trackingName="review_nudge_write_review"
        >
          {t("Write my review")}
        </TrackedButton>
      </Box>
      <Box flex sx={{ justifyContent: "center" }}>
        <TrackedButton
          variant="link"
          onClick={onSkipClick}
          trackingName="review_nudge_skip"
        >
          {t("Skip for now")}
        </TrackedButton>
      </Box>
    </NudgeModal>
  );
}

export default function ReviewReminderTopNoticeBar() {
  const { currentUser } = useSession();
  const location = useLocation();
  const [recentlyEmptyQuery, setRecentlyEmptyQuery] = useLocalStorageState<
    string | undefined
  >("ReviewNudge.recentlyEmptyQuery", undefined);
  const [skips, setSkips] = useLocalStorageState<number>(
    "ReviewNudge.skips",
    0
  );
  const [lastSeen, setLastSeen] = useLocalStorageState<string | undefined>(
    "ReviewNudge.lastSeen",
    undefined
  );
  const recentlySkipped = hasRecentlySkipped(skips, lastSeen);
  const skipRecentEmpty = hasRecentEmptyQuery(recentlyEmptyQuery);
  const hide = !currentUser || recentlySkipped || skipRecentEmpty;

  const { loading, error, data } = useQuery<ReviewReminderTopNoticeBarQuery>(
    reviewReminderQuery,
    { skip: hide }
  );

  if (hide || loading || error) {
    return null;
  }

  const user = data && data.currentUser;
  const section = user && user.enrolledSectionThatNeedsReview;

  if (!section) {
    if (user) {
      setRecentlyEmptyQuery(new Date().toISOString());
    }
    return null;
  }

  const feedbackPath = Routes.sectionFeedbackPath(section.activity, section);
  if (pathFromLocation(location) === feedbackPath) {
    return null;
  }

  const incrementSkips = () => {
    setSkips(skips + 1);
    setLastSeen(new Date().toISOString());
  };

  return (
    <ReviewNudge
      section={section}
      path={feedbackPath}
      incrementSkips={incrementSkips}
    />
  );
}
