import { Box, Icon, SxProps, Theme, visuallyHidden } from "@outschool/backpack";
import { faBars, faCalendarRange, faComment, faSearch } from "@outschool/icons";
import { TFunction, useTranslation } from "@outschool/localization";
import {
  addParamsToUrl,
  forSellerOrgPath,
  leadActivitiesPath,
  learnerPlanPath,
  pathFromLocation,
  searchPath,
  sellerOrgPath,
  teachPath,
  teachTipsUrl
} from "@outschool/routes";
import { useTrackEvent } from "@outschool/ui-analytics";
import { AuthTrigger, useSession } from "@outschool/ui-auth";
import { ExternalLink, TrackedButton } from "@outschool/ui-components-shared";
import {
  CategoryNavbar,
  NavHelpLink,
  useHideLearningPlannerExperiment,
  useMobileSearchBar,
  useSubscriptionCredit
} from "@outschool/ui-components-website";
import {
  ConditionalTrackingPageSection,
  Container,
  useImpressionTracking
} from "@outschool/ui-legacy-component-library";
import { Screen, useOnScreen } from "@outschool/ui-utils";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import * as Routes from "../../../shared/Routes";
import * as User from "../../../shared/User";
import { UserMode, useUserMode, useUserModeRootPath } from "../../lib/UserMode";
import Link from "../Link";
import { DesktopHeaderNavigation } from "./DesktopHeaderNav";
import { EducatorModeToolbar } from "./EducatorModeToolbar";
import GlobalSearchBar, { MobileSearchBar } from "./GlobalSearchBar";
import HeaderLogo from "./HeaderLogo";
import { HeaderMenu, HeaderMenuButtonProps } from "./HeaderMenu";
import HeaderNavItem from "./HeaderNavItem";
import { JoinSubscriptionNavItem } from "./JoinSubscriptionNavItem";
import { useShouldShowMobileFooterTabBar } from "./MobileFooterTabBar";
import { SubscriptionCreditNavItem } from "./SubscriptionCreditNavItem";

interface Props {
  trackingUniqueId?: string;
  isTransparent?: boolean;
  isWhiteText?: boolean;
  allowCategoriesNavbar?: boolean;
  showSearchBox?: boolean;
  hideHeaderRight?: boolean;
  sx?: any;
}

export default function Header({
  trackingUniqueId,
  isTransparent,
  isWhiteText = true,
  allowCategoriesNavbar = false,
  showSearchBox = true,
  hideHeaderRight = false,
  sx
}: Props) {
  const userMode = useUserMode();

  const isEducatorMode = userMode === UserMode.Educator;
  const showCategoriesNavbar = !isEducatorMode && allowCategoriesNavbar;
  const { t } = useTranslation("client\\components\\nav\\Header");
  const { isLoggedIn } = useSession();
  const { hideLearningPlanner } = useHideLearningPlannerExperiment();

  return (
    <ConditionalTrackingPageSection
      name="header"
      uniqueId={trackingUniqueId ? trackingUniqueId : "header"}
    >
      {setImpressionNode => (
        <Box ref={setImpressionNode}>
          {isTransparent ? (
            <TransparentHeader
              isWhiteText={isWhiteText}
              showCategoriesNavbar={showCategoriesNavbar}
              t={t}
            />
          ) : (
            <StandardHeader
              hideFindClasses={isEducatorMode}
              showLearningPlanner={
                !isEducatorMode && isLoggedIn && !hideLearningPlanner
              }
              showCategoriesNavbar={showCategoriesNavbar}
              showSearchBox={showSearchBox}
              rightChildren={hideHeaderRight ? null : undefined}
              sx={sx}
            />
          )}
        </Box>
      )}
    </ConditionalTrackingPageSection>
  );
}

type HeaderFlexContainer = React.PropsWithChildren<{
  sx?: SxProps;
  extended?: boolean;
}>;

function HeaderFlexContainer({
  sx = {},
  extended = false,
  ...props
}: HeaderFlexContainer) {
  return (
    <Container size={extended ? "large" : "medium"}>
      <Box
        flex
        sx={[
          {
            alignItems: "center",
            justifyContent: "space-between"
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        {...props}
      />
    </Container>
  );
}

function StandardHeaderLeft() {
  const rootPath = useUserModeRootPath();

  return (
    <HeaderNavItem
      sx={{
        paddingX: 0,
        display: "flex",
        marginRight: "auto"
      }}
    >
      <Link
        to={rootPath}
        trackingName="nav_logo"
        reloadDocument
        style={{ display: "flex" }}
      >
        <HeaderLogo isWhiteText={false} />
      </Link>
    </HeaderNavItem>
  );
}

function ListingsPageLink({ t }: { t: TFunction }) {
  const { pathname } = useLocation();
  const isSearchPage = pathname === searchPath();
  return (
    <Link to={searchPath()} trackingName="nav_find_classes">
      <Box
        sx={{
          backgroundColor: isSearchPage ? "primary.200" : "common.white",
          color: !isSearchPage ? "grey.800" : "text",
          borderRadius: "100%",
          lineHeight: 0
        }}
      >
        <Icon
          icon={faSearch}
          sx={{
            fontSize: "1.333em",
            margin: "12px"
          }}
        />
      </Box>
      <span style={visuallyHidden}>{t`Search`}</span>
    </Link>
  );
}

function LearningPlannerLink({ t }: { t: TFunction }) {
  const { pathname } = useLocation();
  const isPlannerPage = pathname === learnerPlanPath();

  return (
    <Link to={learnerPlanPath()} trackingName="nav_learner_planner_link">
      <Box
        sx={{
          backgroundColor: isPlannerPage ? "primary.200" : "common.white",
          color: !isPlannerPage ? "grey.800" : "text",
          borderRadius: "100%",
          lineHeight: 0
        }}
      >
        <Icon
          icon={faCalendarRange}
          sx={{
            fontSize: "1.333em",
            margin: "12px"
          }}
        />
      </Box>
      <span style={visuallyHidden}>{t`Planner`}</span>
    </Link>
  );
}

export function MobileHeaderMenuButton({
  isWhiteText
}: {
  isWhiteText?: boolean;
}) {
  const { t } = useTranslation("client\\components\\nav\\Header");
  const [numCloseCount, setNumCloseCount] = React.useState(0);

  return (
    <HeaderMenu
      onClose={() => {
        setNumCloseCount(prev => prev + 1);
      }}
      opensDrawer
    >
      {({ isOpen: _isOpen, buttonRef, ...props }: HeaderMenuButtonProps) => (
        <TrackedButton
          variant="link"
          trackingName="mobile_header_menu_open_button"
          trackingUniqueId={`mobile_header_menu_open_button-${numCloseCount}`}
          trackingSharedProperties={{
            numCloseCount
          }}
          aria-label={t("Menu")}
          ref={buttonRef}
          {...props}
        >
          <Icon
            icon={faBars}
            sx={{
              fontSize: "1.333em",
              color: isWhiteText ? "common.white" : "gray"
            }}
          />
        </TrackedButton>
      )}
    </HeaderMenu>
  );
}

function MobileMessagesLink({ t }: { t: TFunction }) {
  const { isLeader, currentUser } = useSession();
  const unreadConversationsCount = User.unreadConversationsCount(currentUser);

  const { trackTouch } = useImpressionTracking({
    trackingLabel: "mobile_nav_header_messages",
    uniqueId: "mobile_nav_header_messages",
    trackingEventName: "mobile_nav_header_messages_event"
  });

  return (
    <Box
      as={Link}
      onClick={() => trackTouch()}
      sx={{
        position: "relative",
        display: "flex",
        borderRadius: "100%",
        color: "grey.950",
        ['&[aria-current="page"]']: {
          backgroundColor: "primary.200",
          color: "primary.main"
        }
      }}
      to={Routes.conversationsPath(
        isLeader ? UserMode.Educator : UserMode.Parent
      )}
    >
      {unreadConversationsCount > 0 && (
        <Box
          role="status"
          aria-label={t("{{unreadCount}} unread message", {
            unreadCount: unreadConversationsCount
          })}
          sx={(theme: $TSFixMe) => ({
            position: "absolute",
            top: "0.5rem",
            right: "0.3rem",
            border: "1px solid white",
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            borderRadius: "50%",
            fontSize: "1.2rem",
            minWidth: "1.7rem",
            lineHeight: "1.5rem",
            textAlign: "center"
          })}
        >
          {unreadConversationsCount}
        </Box>
      )}

      <Icon
        aria-label={t("Conversations")}
        icon={faComment}
        sx={{
          fontSize: "1.333em",
          margin: "12px",
          color: "inherit"
        }}
      />
    </Box>
  );
}

function StandardHeaderRight({
  hideFindClasses,
  showLearningPlanner,
  t
}: $TSFixMe) {
  const isMobile = Screen.useIsMobile();
  const { isLoggedIn } = useSession();
  const mobileFooterIsShowing = useShouldShowMobileFooterTabBar();
  const { hasSubscription: hasActiveSubscription, subscriptionEnabled } =
    useSubscriptionCredit();

  return (
    <>
      {isMobile ? (
        <>
          {hasActiveSubscription && <SubscriptionCreditNavItem />}
          {subscriptionEnabled && !hasActiveSubscription && (
            <JoinSubscriptionNavItem />
          )}
          <Box
            flex
            sx={{
              gap: "0.25em",
              paddingY: "0.5em"
            }}
          >
            {isLoggedIn && <MobileMessagesLink t={t} />}
            {!mobileFooterIsShowing && !hideFindClasses && (
              <ListingsPageLink t={t} />
            )}
            {!mobileFooterIsShowing && showLearningPlanner && (
              <LearningPlannerLink t={t} />
            )}
            <MobileHeaderMenuButton />
          </Box>
        </>
      ) : (
        <DesktopHeaderNavigation />
      )}
    </>
  );
}

const HeaderToolbar = ({ showSearchBox = true }) => {
  const isMobile = Screen.useIsMobile();
  const isEducatorMode = useUserMode() === UserMode.Educator;
  const { setIsHeaderMounted } = useMobileSearchBar();

  React.useEffect(() => {
    const t = setTimeout(() => setIsHeaderMounted(true), 1000);
    return () => {
      clearTimeout(t);
      setIsHeaderMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pathname } = useLocation();

  const classDetailPathPattern = /^\/classes\/[\w-]+$/;
  const isClassDetailPagePath = classDetailPathPattern.test(pathname);

  if (isMobile && showSearchBox && !isEducatorMode && !isClassDetailPagePath) {
    return <MobileSearchBar />;
  }

  if (!isMobile) {
    if (isEducatorMode) {
      return <EducatorModeToolbar />;
    }
    return <GlobalSearchBar showSearchBox={showSearchBox} />;
  }
  return null;
};

interface StandardHeaderProps {
  sx?: any;
  hideFindClasses?: boolean;
  showLearningPlanner?: boolean;
  leftChildren?: any;
  rightChildren?: any;
  showCategoriesNavbar?: boolean;
  showSearchBox?: boolean;
}
export const StandardHeader = ({
  sx,
  hideFindClasses,
  showLearningPlanner,
  leftChildren,
  rightChildren,
  showCategoriesNavbar,
  showSearchBox = true
}: StandardHeaderProps) => {
  const ref = React.useRef<HTMLElement>(null);
  const { t } = useTranslation("client\\components\\nav\\Header"); //StickyMobileHeader imports this as well

  const { setIsHeaderOnScreen } = useMobileSearchBar();
  const isOnScreen = useOnScreen(ref.current);

  const sxMemo = useMemo(() => {
    return (theme: Theme) => ({
      [theme.breakpoints.down("md")]: {
        borderTop: "1px solid"
      },
      borderColor: "#808080"
    });
  }, []);

  React.useEffect(() => {
    setIsHeaderOnScreen(isOnScreen);
  }, [setIsHeaderOnScreen, isOnScreen]);

  if (leftChildren === undefined) {
    // if null, omit left header
    leftChildren = <StandardHeaderLeft />;
  }
  if (rightChildren === undefined) {
    // if null, omit right header
    rightChildren = (
      <StandardHeaderRight
        hideFindClasses={hideFindClasses}
        showLearningPlanner={showLearningPlanner}
        t={t}
      />
    );
  }
  return (
    <Box
      ref={ref}
      sx={{
        zIndex: 201,
        position: "relative",
        backgroundColor: "common.white",
        boxShadow: "0px 1px 12px rgba(0, 0, 0, 0.08)",
        ...sx
      }}
    >
      <Box
        flex
        sx={(theme: $TSFixMe) => ({
          gap: "1.5em",
          paddingTop: 5,
          maxWidth: 1255,
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",

          [theme.breakpoints.down("sm")]: {
            paddingTop: 0
          },

          "@media (max-width: 1255px)": {
            mx: 24
          }
        })}
      >
        {leftChildren}
        <HeaderToolbar showSearchBox={showSearchBox} />
        {rightChildren}
      </Box>
      {showCategoriesNavbar && <CategoryNavbar sx={sxMemo} />}
    </Box>
  );
};

function TransparentHeader({ isWhiteText, showCategoriesNavbar, t }: $TSFixMe) {
  const isMobile = Screen.useIsMobile();
  const rootPath = useUserModeRootPath();
  return (
    <Box
      sx={{
        backgroundColor: "common.white"
      }}
    >
      <HeaderFlexContainer>
        <Box flex>
          <Link to={rootPath} trackingName="nav_logo" reloadDocument>
            <HeaderNavItem
              variant={isWhiteText ? "transparent" : "standard"}
              sx={
                !isWhiteText && {
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.5)"
                  }
                }
              }
            >
              <HeaderLogo isWhiteText={isWhiteText} />
            </HeaderNavItem>
          </Link>
        </Box>

        {isMobile ? (
          <MobileHeaderMenuButton isWhiteText={isWhiteText} />
        ) : (
          <DesktopTransparentHeaderNav isWhiteText={isWhiteText} t={t} />
        )}
      </HeaderFlexContainer>

      {showCategoriesNavbar && (
        <CategoryNavbar
          sx={(theme: Theme) => ({
            [theme.breakpoints.down("md")]: {
              borderTop: "1px solid"
            },
            borderBottom: "1px solid",
            borderColor: "#808080"
          })}
        />
      )}
    </Box>
  );
}

function DesktopTransparentHeaderNav({ isWhiteText, t }: $TSFixMe) {
  const { isLoggedIn } = useSession();
  const { pathname } = useLocation();

  const isTeacherPath = pathname === teachPath();
  const isSellerOrgPath =
    pathname === forSellerOrgPath() || pathname === sellerOrgPath();
  const trackEvent = useTrackEvent();

  return (
    <Box
      flex
      sx={{
        gap: "0.5em"
      }}
    >
      {!isLoggedIn && isTeacherPath && (
        <ExternalLink url={teachTipsUrl()} trackingName="nav_library_link">
          <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
            {t`Educator Library`}
          </HeaderNavItem>
        </ExternalLink>
      )}

      <Link to={leadActivitiesPath()} trackingName="nav_teach_link">
        <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
          {t`Teach`}
        </HeaderNavItem>
      </Link>

      <NavHelpLink>
        <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
          {t`Help`}
        </HeaderNavItem>
      </NavHelpLink>
      {!isLoggedIn && (
        <Link to={Routes.loginPath()} trackingName="nav_login_link">
          <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
            {t`Log In`}
          </HeaderNavItem>
        </Link>
      )}
      {!isLoggedIn && !isSellerOrgPath && (
        <Link
          to={addParamsToUrl(pathFromLocation(window.location), {
            authTrigger: AuthTrigger.JOIN_OUTSCHOOL,
            signup: true
          })}
          useHardNav={true}
        >
          <HeaderNavItem
            variant={isWhiteText ? "transparent" : "standard"}
            sx={{ color: isWhiteText ? "white" : "#4B01D4" }}
            onClick={() => {
              trackEvent("click_nav_signup_link");
            }}
          >
            {t`Join`}
          </HeaderNavItem>
        </Link>
      )}
    </Box>
  );
}
