import { SearchFilters } from "@outschool/gql-backend-generated";
import {
  SearchItem,
  usePlaceholderSuggestions
} from "@outschool/ui-components-website";
import { useComponentTrackingContext } from "@outschool/ui-legacy-component-library";
import { useCombobox } from "downshift";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  useSearchSuggestions,
  useSelectInitialSearchItem,
  useSetSearchSuggestions
} from "../../../shared/client/queries/ParentSearchSuggestions";
import { ExtendedSearchFiltersType } from "../../lib/useSearchFilters";

type EmptyFilterIgnoreFields =
  | "availableTimes"
  | "excludeNewOneOnOneFormat"
  | "excludeNonOngoingOneOnOne"
  | "excludeTimeWindows"
  | "includeInProgressFixedLengthLiveFormat"
  | "includeSelfPaced"
  | "isAvailable"
  | "order"
  | "q"
  | "seed"
  | "showListings"
  | "timeZone"
  | "topics"
  | "userName"
  | "userUid";

export const emptyFilter: Record<
  keyof Omit<SearchFilters, EmptyFilterIgnoreFields>,
  null
> = {
  activityRatingMin: null,
  adminTags: null,
  age: null,
  capacityMax: null,
  capacityMin: null,
  curriculums: null,
  delivery: null,
  dow: null,
  durationSessionMin: null,
  durationWeeksMax: null,
  durationWeeksMin: null,
  enabledBooleanFilters: null,
  endBy: null,
  endByTime: null,
  englishProficiencyLevel: null,
  format: null,
  gradeLevel: null,
  hasAssessment: null,
  hasGrades: null,
  hasHomework: null,
  hasFilledOutUniqueLearningNeeds: null,
  listUid: null,
  multiTermQuery: null,
  fundingPrograms: null,
  languageOfInstruction: null,
  pricePerCourseMax: null,
  pricePerCourseMin: null,
  pricePerMeetingMin: null,
  pricePerMeetingMax: null,
  priceCreditsPerCourseMax: null,
  priceCreditsPerCourseMin: null,
  priceCreditsPerMeetingMin: null,
  priceCreditsPerMeetingMax: null,
  standards: null,
  starred: null,
  teacherRatingMin: null,
  time: null,
  timeOfDay: null,
  startAfter: null,
  startAfterTime: null,
  startBefore: null,
  theme: null,
  uniqueLearningNeeds: null,
  weeklyMeetingsMax: null,
  weeklyMeetingsMin: null
};

export function queryFromSearchItem(
  searchItem: SearchItem,
  priorFilters: Pick<ExtendedSearchFiltersType, "order">
): Pick<
  ExtendedSearchFiltersType,
  "userUid" | "userName" | "q" | "order" | "originalSpelling"
> {
  const isTeacher =
    searchItem.searchFilters?.userUid && searchItem.searchFilters?.userName;

  // clear all existing filters if it's saved or recent
  // otherwise, the old filters will stick around
  const searchFilters =
    searchItem.type === "saved" || searchItem.type === "recent"
      ? {
          ...emptyFilter,
          ...searchItem.searchFilters
        }
      : searchItem.searchFilters;

  if (
    searchItem.type === "teacher" ||
    ((searchItem.type === "recent" || searchItem.type === "saved") && isTeacher)
  ) {
    return {
      ...searchFilters,
      q: undefined,
      userUid: searchItem.uid,
      userName: searchItem.name,
      order: "upcoming"
    };
  } else {
    const res = {
      ...searchFilters,
      q: searchItem.value || searchItem.name,
      userUid: undefined,
      userName: undefined,
      originalSpelling: undefined,
      order: priorFilters.order === "upcoming" ? undefined : priorFilters.order
    };

    return res;
  }
}

export default function useSearchWithSuggestions({
  onSearchFieldChange,
  onSearchFieldCommit,
  queryOptions = { ignore: [] },
  showSearchSuggestions = false
}: {
  onSearchFieldChange: (item: SearchItem) => void;
  onSearchFieldCommit: (item: SearchItem) => void;
  queryOptions?: { ignore: Array<string> };
  showSearchSuggestions?: boolean;
}) {
  const [didUserFocusOnInput, setDidUserFocusOnInput] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchItem[]>([]);
  const [prevSearchQuery, setPrevSearchQuery] = useState("");

  const [debouncedFetchSuggestions] = useDebouncedCallback(options => {
    fetchSuggestions(options);
  }, 300);

  const track = useComponentTrackingContext();

  const {
    loading: loadingSuggestions,
    fetchSuggestions,
    popularTermSuggestions,
    teacherSuggestions,
    topicSuggestions,
    error
  } = useSearchSuggestions(5, queryOptions.ignore);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectedItem: selectedComboboxItem,
    inputValue,
    setInputValue,
    selectItem: selectComboboxItem,
    closeMenu
    // Beware: Omitting this generic type caused TS4.9 to crash indecipherably
  } = useCombobox<SearchItem>({
    items: searchSuggestions,
    itemToString: item => {
      if (!item) {
        return "";
      }
      if (item.type === "keyword") {
        return inputValue || "";
      }
      return item?.value || item.name || "";
    },
    onSelectedItemChange: ({ selectedItem, type, inputValue }) => {
      if (type !== useCombobox.stateChangeTypes.FunctionSetInputValue) {
        closeMenu();
        if (!selectedItem) {
          return;
        }

        onSearchFieldCommit(selectedItem);
        if (inputValue && didUserFocusOnInput && selectedItem) {
          track(
            "listings_typeahead_touch",
            {
              inputValue,
              suggestionType: selectedItem.type,
              suggestionValue: selectedItem.value || selectedItem.name,
              suggestionUid: selectedItem.uid,
              positionInList: highlightedIndex,
              experiments: {
                mobileSearch: false
              }
            },
            {
              // Redshift only options
              integrations: {
                All: false
              }
            }
          );
        }
      }
    },
    onInputValueChange: ({ type, inputValue }) => {
      if (type === useCombobox.stateChangeTypes.InputChange) {
        onSearchFieldChange({
          type: "keyword",
          name: inputValue,
          uid: inputValue
        });
      }
      if (!inputValue) {
        setSearchSuggestions([]);
      } else {
        debouncedFetchSuggestions({ variables: { query: inputValue } });
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: false,
            inputValue: prevSearchQuery
          };
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return state;
        default:
          return changes;
      }
    }
  });

  const placeholderSuggestions = usePlaceholderSuggestions();

  useSetSearchSuggestions({
    setSearchSuggestions,
    inputValue,
    popularTermSuggestions,
    teacherSuggestions,
    topicSuggestions,
    placeholderSuggestions: showSearchSuggestions
      ? (placeholderSuggestions as SearchItem[])
      : []
  });

  useSelectInitialSearchItem({
    selectComboboxItem,
    selectedComboboxItem,
    setInputValue,
    setPrevSearchQuery
  });

  const onInputFocus = () => {
    setDidUserFocusOnInput(true);
    openMenu();

    if (inputValue) {
      debouncedFetchSuggestions({ variables: { query: inputValue } });
    }
  };

  const onUserSubmit = (item: SearchItem) => {
    const itemInputValue = item.name || item.value;
    setPrevSearchQuery(itemInputValue || "");
  };

  return {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    inputValue,
    setInputValue,
    onInputFocus,
    onUserSubmit,
    loadingSuggestions,
    suggestionsOpen: isOpen,
    searchSuggestions,
    suggestionsHighlightedIndex: highlightedIndex,
    error,
    closeMenu
  };
}
