import { Box, Button, Theme, Tooltip, TooltipProps } from "@outschool/backpack";
import {
  TrackingEvent,
  makeBannerTrackingName
} from "@outschool/funding-programs-shared";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { getEsaState, useSession } from "@outschool/ui-auth";
import React from "react";

export function SubscriptionEsaDisableTooltip({
  tooltip,
  tooltipComponent,
  component,
  children,
  ...props
}: Partial<TooltipProps> & {
  tooltip?: string;
  tooltipComponent?: React.ReactNode;
  component?: string;
  children: React.ReactNode;
}) {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionEsaDisableTooltip"
  );
  const { isEsaSession, destroyEsaSession, currentUserRefetch } = useSession();
  const trackEvent = useTrackEvent();
  const esaState = getEsaState();

  return (
    <Tooltip
      {...props}
      title={
        isEsaSession ? (
          <>
            {!!tooltipComponent && tooltipComponent}
            {!tooltipComponent && (
              <>
                {tooltip}
                {", "}
                <Button
                  variant="link"
                  sx={(theme: Theme) => ({
                    fontSize: theme.typography.button,
                    fontWeight: "400",
                    verticalAlign: "unset"
                  })}
                  onClick={() => {
                    trackEvent(
                      makeBannerTrackingName(TrackingEvent.OptOutTouch),
                      {
                        fundingProgram: esaState,
                        component
                      }
                    );
                    destroyEsaSession();
                    currentUserRefetch();
                  }}
                >
                  {t("exit the ClassWallet session")}.
                </Button>
              </>
            )}
          </>
        ) : null
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
