import React from "react";
import { matchPath, useLocation } from "react-router";

import { TRANSLATION_SERVICE_URL } from "../../shared/Env";
import ROUTES from "../routes";

/**
 * Checks whether or not the current route should be translated.
 * @returns boolean
 */
export const useIsTranslating = () => {
  const { pathname } = useLocation();

  const pathIsTranslated = React.useMemo(() => {
    // Find first route definition that matches the current pathname.
    // Uses matchPath, which is exactly how react-router does it under the hood.
    const matchedRouteDefiniton = ROUTES.find(routeDefinition =>
      matchPath(pathname, {
        exact: true, // App.tsx route switcher defaults to exact
        ...routeDefinition
      })
    );

    return matchedRouteDefiniton?.isTranslated ?? false;
  }, [pathname]);

  return pathIsTranslated && !!TRANSLATION_SERVICE_URL;
};
