import { useUserWalletSummary as _useUserWalletSummary } from "@outschool/ui-components-website";

/**
 * @deprecated Use @outschool/ui-components-website hook instead.
 * This file is required for the WalletNavItem jest test.
 * It can be removed once the test is refactored.
 */
export const useUserWalletSummary = (skip = false) => {
  return _useUserWalletSummary(skip);
};
